import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Button, IconButton, TextField, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { ReactComponent as Unlocked } from "../../../assets/images/lock_open_right.svg";
import PriceListLevelImageCell from "../../../utils/reactTable/cellRenderers/PriceListLevelImageCell";
import { ImageTooltipRenderer } from "../../../utils/reactTable/tooltipRenderer/TooltipRenderer";
import { syncStatusCodes } from "./EUCountryLevelPrices";
import MaterialAlertException from "./MaterialAlertException";
import {
    APPROVAL_STATUS,
    ApprovalStatusRenderer,
    checkMaterialSelectionValidity,
    dateComparator,
} from "./euHelper";
import { numberColFilterParams } from "../../../utils/helpers/utility_helpers";

import styles from "./EUPricePlanning.module.scss";

export const hierarchyColDef = {
    valueGetter: (params) => params.data[params.colDef.field] ?? "",
    valueFormatter: ({ value }) => value,
};

const dollarFormatter = ({ value }) =>
    value === null || value === undefined || isNaN(parseFloat(value))
        ? "-"
        : "$" + parseFloat(value)?.toFixed(2);

export function priceRuleLinkRenderer({ value, data }) {
    const { price_rule_status } = data;

    if (!value) return <>-</>;

    return (
        <Tooltip
            title={`Price rule is ${
                price_rule_status === 2 ? "in revision state. " : "applied. "
            }Click to view rule`}
            enterDelay={800}
            leaveDelay={0}
        >
            <Link
                to={`/price-rules/view?id=${data.price_rule_id}`}
                style={{ textDecoration: "none" }}
            >
                <span
                    style={{
                        color: price_rule_status === 2 ? "#da1e28" : "#0055AF",
                        letterSpacing: "0.08px",
                        font: "normal normal 600 14px/21px Poppins, sans-serif",
                        cursor: "pointer",
                    }}
                >
                    {value}
                </span>
            </Link>
        </Tooltip>
    );
}

export const DiscountTableLinkComponent = ({
    data: { discount_config_id: id, discount_table: value },
}) =>
    id ? (
        <Tooltip
            title={`Click to go on ${value}`}
            enterDelay={800}
            leaveDelay={0}
        >
            <Link
                to={`/discount-config/view-discount-table?id=${id}`}
                style={{
                    textDecoration: "none",
                    color: "#0055AF",
                    letterSpacing: "0.08px",
                    font: "normal normal 600 14px/21px Poppins, sans-serif",
                    cursor: "pointer",
                }}
            >
                {value}
            </Link>
        </Tooltip>
    ) : (
        "-"
    );

export const countryLevelPriceCols = [
    {
        field: "",
        pinned: true,
        checkboxSelection: false,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        sortable: false,
        lockPinned: true,
        suppressMovable: true,
        filter: false,
        flex: 0,
        width: 50,
        menuTabs: [],
        valueFormatter: () => "",
    },
    {
        headerName: "MATERIAL NUMBER",
        field: "material_number",
        pinned: true,
        lockPinned: true,
        suppressMovable: true,
        cellRenderer: MaterialAlertException,
        cellRendererParams: { section: "countryPriceAlertsAndExceptions" },
        cellClass: "bigNumber",
        headerClass: "ag-grid-header-overflow-visible",
        width: 234,
    },
];

export const attributeOptions = [
    {
        label: "IMAGE",
        value: "image_url",
        pinned: true,
        lockPinned: true,
        cellStyle: {
            display: "flex",
            justifyContent: "center",
        },
        suppressMovable: true,
        cellRenderer(data) {
            return <PriceListLevelImageCell data={data} />;
        },
        sortable: false,
        filter: false,
        showByDefault: true,
        width: 90,
        tooltipComponent: ImageTooltipRenderer,
        tooltipField: "image_url",
    },
    {
        value: "status",
        label: "STATUS",
        cellRenderer({ value }) {
            return (
                <>
                    <span
                        className={styles["status-dot"]}
                        style={{
                            backgroundColor:
                                syncStatusCodes[value || 0].backgroundColor,
                        }}
                    />{" "}
                    {syncStatusCodes[value || 0].text}
                </>
            );
        },
        filterValueGetter({ data }) {
            if (data.status === null || data.status === undefined) return "";
            return syncStatusCodes[data.status].text;
        },
        showByDefault: true,
    },
    {
        label: "PARENT MATERIAL NUMBER",
        value: "parent_material",
        showByDefault: true,
    },
    {
        label: "PARENT SEASON CODE",
        value: "parent_season",
        showByDefault: true,
    },
    {
        label: "PRICE ESTABLISHMENT",
        value: "price_establishment",
        showByDefault: true,
    },
    {
        label: "TYPE OF PE",
        value: "type_of_pe",
        showByDefault: true,
    },
    {
        label: "APPROVAL STATUS",
        value: "approval_status",
        showByDefault: true,
        cellRenderer: ApprovalStatusRenderer,
        filterValueGetter({ data }) {
            if (
                data.approval_status === null ||
                data.approval_status === undefined
            )
                return "";
            return APPROVAL_STATUS[data.approval_status];
        },
    },
    {
        label: "SEASON CODE",
        value: "season_code",
        showByDefault: true,
    },
    {
        label: "SPECIAL ATTRIBUTE",
        value: "special_attribute",
        showByDefault: true,
    },
    {
        label: "SEASON NAME",
        value: "season_name",
        showByDefault: true,
    },
    {
        label: "PRICE RULE",
        value: "price_rule_name",
        cellRenderer: priceRuleLinkRenderer,
        showByDefault: true,
    },
    {
        label: "DISCOUNT TABLE",
        value: "discount_table",
        showByDefault: true,
        cellRenderer: DiscountTableLinkComponent,
        comparator: (a, b) => a.toLowerCase().localeCompare(b.toLowerCase()),
    },
    {
        label: "DISCOUNT",
        value: "discount",
        showByDefault: true,
        hide: true,
    },
    {
        label: "PLANNING BRAND",
        value: "planning_brand",
        showByDefault: true,
        ...hierarchyColDef,
    },
    {
        label: "PLANNING SUB BRAND",
        value: "planning_sub_brand",
        showByDefault: true,
        ...hierarchyColDef,
    },
    {
        label: "BOARD NUMBER",
        value: "board_number",
        showByDefault: true,
    },
    {
        label: "SHORT BOARD",
        value: "short_board",
        showByDefault: true,
    },
    {
        label: "MSRP PRICE EFFECTIVE DATE",
        value: "msrp_effective_date",
        showByDefault: true,
        comparator: dateComparator,
    },
    {
        label: "WHSL PRICE EFFECTIVE DATE",
        value: "wholesale_effective_date",
        showByDefault: true,
        comparator: dateComparator,
    },
    {
        label: "GFE MATERIAL STATUS",
        value: "gfe_material_status",
        showByDefault: true,
    },
    {
        label: "US MSRP",
        value: "us_msrp_amt",
        valueFormatter: dollarFormatter,
        showByDefault: true,
        filterParams: numberColFilterParams,
    },
    {
        label: "US WHSL",
        value: "us_whsl_amt",
        valueFormatter: dollarFormatter,
        showByDefault: true,
        filterParams: numberColFilterParams,
    },
    {
        label: "Brand Group",
        value: "product_h4_name",
        ...hierarchyColDef,
    },
    {
        label: "Brand Group ID",
        value: "product_h4_id",
        ...hierarchyColDef,
    },
    {
        label: "Merch Division",
        value: "product_h1_name",
        ...hierarchyColDef,
    },
    {
        label: "Merch Division ID",
        value: "product_h1_id",
        ...hierarchyColDef,
    },
    {
        label: "Merch Org",
        value: "product_h2_name",
        ...hierarchyColDef,
    },
    {
        label: "Merch Org Code",
        value: "product_h2_id",
        ...hierarchyColDef,
    },
    {
        label: "Gender",
        value: "product_h3_name",
        ...hierarchyColDef,
    },
    {
        label: "Gender Code",
        value: "product_h3_id",
        ...hierarchyColDef,
    },
    {
        label: "Brand",
        value: "product_h5_name",
        ...hierarchyColDef,
    },
    {
        label: "Brand ID",
        value: "product_h5_id",
        ...hierarchyColDef,
    },
    {
        label: "Merch Segment",
        value: "product_h6_name",
        ...hierarchyColDef,
    },
    {
        label: "Merch Segment Code",
        value: "product_h6_id",
        ...hierarchyColDef,
    },
    {
        label: "Merch Size Group",
        value: "product_h7_name",
        ...hierarchyColDef,
    },
    {
        label: "Merch Size Group Code",
        value: "product_h7_id",
        ...hierarchyColDef,
    },
    {
        label: "Merch Category",
        value: "product_h8_name",
        ...hierarchyColDef,
    },
    {
        label: "Merch Category Code",
        value: "product_h8_id",
        ...hierarchyColDef,
    },
    {
        label: "Product Category",
        value: "product_h9_name",
        ...hierarchyColDef,
    },
    {
        label: "Product Category Code",
        value: "product_h9_id",
        ...hierarchyColDef,
    },
    {
        label: "Product Class",
        value: "product_h10_name",
        ...hierarchyColDef,
    },
    {
        label: "Product Class Code",
        value: "product_h10_id",
        ...hierarchyColDef,
    },
    {
        label: "Product Subclass",
        value: "product_h11_name",
        ...hierarchyColDef,
    },
    {
        label: "Product Subclass Code",
        value: "product_h11_id",
        ...hierarchyColDef,
    },
    {
        label: "Plan Level 1",
        value: "product_h12_name",
        ...hierarchyColDef,
    },
    {
        label: "Plan Level 1 Code",
        value: "product_h12_id",
        ...hierarchyColDef,
    },
    {
        label: "Plan Level 2",
        value: "product_h13_name",
        ...hierarchyColDef,
    },
    {
        label: "Plan Level 2 Code",
        value: "product_h13_id",
        ...hierarchyColDef,
    },
    {
        label: "Plan Level 3",
        value: "product_h14_name",
        ...hierarchyColDef,
    },
    {
        label: "Plan Level 3 Code",
        value: "product_h14_id",
        ...hierarchyColDef,
    },
    {
        label: "Plan Level 4",
        value: "product_h15_name",
        ...hierarchyColDef,
    },
    {
        label: "Plan Level 4 Code",
        value: "product_h15_id",
        ...hierarchyColDef,
    },
    {
        label: "Plan Level 5",
        value: "product_h16_name",
        ...hierarchyColDef,
    },
    {
        label: "Plan Level 5 Code",
        value: "product_h16_id",
        ...hierarchyColDef,
    },
    {
        label: "Material Description",
        value: "material_desc",
    },
    {
        label: "Material Group",
        value: "material_group",
    },
    {
        label: "Material Group Code",
        value: "material_group_code",
    },
    {
        label: "Style Number",
        value: "style_number",
    },
    {
        label: "Color Description",
        value: "color_description",
        showByDefault: true,
    },
    {
        label: "Color",
        value: "color",
    },
    {
        label: "Color Code",
        value: "color_code",
    },
    {
        label: "NRF Color Code",
        value: "nrf_color_code",
    },
    {
        label: "Merch Concept",
        value: "merch_concept",
    },
    {
        label: "Merch Concept Code",
        value: "merch_concept_code",
    },
    {
        label: "Merch Fabric",
        value: "merch_fabric",
    },
    {
        label: "Merch Fabric Code",
        value: "merch_fabric_code",
    },
    {
        label: "Plan Size Group",
        value: "plan_size_group",
    },
    {
        label: "Plan Size Group Code",
        value: "plan_size_group_code",
    },
    {
        label: "Product Label",
        value: "product_label",
    },
    {
        label: "Product Label Code",
        value: "product_label_code",
    },
    {
        label: "Pattern",
        value: "pattern",
    },
    {
        label: "Collection",
        value: "collection",
    },
    {
        label: "Collection Code",
        value: "collection_code",
    },
    {
        label: "SAP Status",
        value: "sap_status",
    },
    {
        label: "UPC Status",
        value: "upc_status",
    },
    {
        label: "IP Price Export Status",
        value: "ip_price_export_status",
    },
    {
        label: "GFE Pre-pack",
        value: "gfe_pre_pack",
    },
    {
        label: "Legacy Material Number",
        value: "legacy_material_nbr",
        cellClass: "bigNumber",
    },
    {
        label: "Vendor Material Number",
        value: "vendor_material_nbr",
    },
    {
        label: "Item Number",
        value: "item_number",
    },
    {
        label: "Model Name",
        value: "model_name",
    },
    {
        label: "Model Long Description",
        value: "model_long_description",
    },
    {
        label: "Model Short Description",
        value: "model_short_description",
    },
    {
        label: "First Available Date",
        value: "first_available_dt",
    },
    {
        label: "DTC Projection Units",
        value: "dtc_projection_units",
    },
    {
        label: "WHSL Projection Units",
        value: "wholesale_projection_units",
    },
    {
        label: "DTC Projection Sales",
        value: "sales_dtc_projected",
    },
    {
        label: "WHSL Projection Sales",
        value: "sales_wholesale_projected",
    },
    {
        label: "DTC Projection Margin",
        value: "margin_dtc_projected",
    },
    {
        label: "WHSL Projection Margin",
        value: "margin_wholesale_projected",
    },
];

export function LogValueRenderer({ value, type }) {
    if (value === null || value === undefined) return "-";
    if (type === "status")
        return (
            <>
                <span
                    className={styles["status-dot"]}
                    style={{
                        backgroundColor:
                            syncStatusCodes[value]?.backgroundColor,
                    }}
                />{" "}
                {syncStatusCodes[value]?.text ?? "-"}
            </>
        );
    return value;
}

const minorFormatter = (isEditing, editVal, value) =>
    (isEditing ? parseFloat(editVal) || 0 : value).toFixed(2);

export function PriceValueRenderer(props) {
    const {
        rowIndex,
        node,
        value,
        column,
        editMode,
        selectedRows,
        customKey,
        keys,
        showAuditLog,
        type,
        editData,
        setEditData,
        toggleLock,
        valuePrefix,
    } = props;

    const { role_details, allowed_regions, allowed_channel_hierarchies } =
        useSelector((state) => state.global.userPermissions);

    const isEditing = useMemo(
        () =>
            editMode &&
            selectedRows.find((e) => +e.rowIndex === +rowIndex) !== undefined,
        [editMode, selectedRows, rowIndex]
    );
    const editVal = useMemo(
        () =>
            editData[props.data.id]?.[column.colId.split(".")[0]]?.[
                type === "MSRP"
                    ? "msrp_generated_value"
                    : "wholesale_generated_value"
            ],
        [props, column, type, editData]
    );
    if ([undefined, null].includes(value)) return "-";

    const showInput =
        isEditing &&
        !customKey?.[
            type === "MSRP" ? "is_msrp_cloned" : "is_wholesale_cloned"
        ] &&
        !(customKey.key === "EURO STANDARD" && type === "MSRP") &&
        node.data[customKey.key]?.[
            `is_${type === "MSRP" ? "msrp" : "whsl"}_locked`
        ] === 0;

    return (
        <>
            {value !== "-" &&
                (node.data[customKey.key]?.[
                    `is_${type === "MSRP" ? "msrp" : "whsl"}_locked`
                ] ? (
                    <IconButton
                        style={{
                            color: "#1d1d1d",
                            margin: "0 2px 0 -8px",
                            opacity: checkMaterialSelectionValidity(
                                role_details,
                                allowed_regions,
                                allowed_channel_hierarchies,
                                node.data
                            )
                                ? 1
                                : 0,
                            pointerEvents: checkMaterialSelectionValidity(
                                role_details,
                                allowed_regions,
                                allowed_channel_hierarchies,
                                node.data
                            )
                                ? undefined
                                : "none",
                        }}
                        onClick={() => {
                            toggleLock(
                                0,
                                "atomic",
                                {
                                    [node.data[customKey.key]
                                        .price_file_generated_price_uid]: {
                                        [`is_${
                                            type === "MSRP" ? "msrp" : "whsl"
                                        }_locked`]: 0,
                                    },
                                },
                                { data: node.data, key: customKey.key }
                            );
                        }}
                        disabled={editMode}
                    >
                        <LockOutlinedIcon style={{ fontSize: "initial" }} />
                    </IconButton>
                ) : (
                    <IconButton
                        style={{
                            color: "#1d1d1d",
                            margin: "0 2px 0 -8px",
                            opacity: checkMaterialSelectionValidity(
                                role_details,
                                allowed_regions,
                                allowed_channel_hierarchies,
                                node.data
                            )
                                ? 1
                                : 0,
                            pointerEvents: checkMaterialSelectionValidity(
                                role_details,
                                allowed_regions,
                                allowed_channel_hierarchies,
                                node.data
                            )
                                ? undefined
                                : "none",
                        }}
                        className={editMode ? "" : "parent-hover-show"}
                        onClick={() => {
                            toggleLock(
                                1,
                                "atomic",
                                {
                                    [node.data[customKey.key]
                                        .price_file_generated_price_uid]: {
                                        [`is_${
                                            type === "MSRP" ? "msrp" : "whsl"
                                        }_locked`]: 1,
                                    },
                                },
                                { data: node.data, key: customKey.key }
                            );
                        }}
                        disabled={editMode}
                    >
                        <Unlocked style={{ height: "17px", width: "17px" }} />
                    </IconButton>
                ))}
            {showInput ? (
                <TextField
                    value={editVal}
                    type="number"
                    min={0}
                    onChange={(e) => {
                        setEditData(
                            editData,
                            props,
                            column,
                            type,
                            e,
                            keys,
                            customKey
                        );
                    }}
                />
            ) : value === "-" ? (
                "-"
            ) : (
                <Button
                    variant="text"
                    onClick={() =>
                        showAuditLog({
                            data: node.data,
                            value,
                            price_list_name: customKey.key,
                            type,
                            logType: props?.colDef?.headerName?.includes(
                                "STORE PRICE"
                            )
                                ? "store_price"
                                : "price",
                        })
                    }
                    style={{ justifyContent: "start" }}
                >
                    {value === "-"
                        ? "-"
                        : `${valuePrefix} 
                    ${minorFormatter(isEditing, editVal, value)}`}
                </Button>
            )}
        </>
    );
}

PriceValueRenderer.propTypes = {
    selectedRows: PropTypes.array,
    data: PropTypes.shape({ id: PropTypes.number }),
    column: PropTypes.shape({ colId: PropTypes.string }),
    node: PropTypes.shape({ data: PropTypes.shape }),
    customKey: PropTypes.shape({ key: PropTypes.string }),
};
