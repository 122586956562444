import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import _ from "lodash";
import { Modal, Button } from "@mui/material";
import PolicyIcon from "@mui/icons-material/Policy";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";

import SideBar from "../sidebar/SideBar";
import Header from "../header/Header";
import LoadingOverlay from "../../ui/loader/Loader";
import {
    setNavigateAfterUnsavedEdit,
    setTriggerUnsavedEdit,
} from "../../../store/features/global/global";
import UpdateBanner from "../header/UpdateBanner";

import "./Layout.scss";

const getIcon = (screen) => {
    let icon = null;
    switch (screen) {
        case "price_rules":
            icon = React.createElement(PublicOutlinedIcon);
            break;
        case "configuration_alert":
            icon = React.createElement(SettingsOutlinedIcon);
            break;
        case "workbench":
            icon = React.createElement(BusinessCenterOutlinedIcon);
            break;
        case "audit_log_download":
            icon = React.createElement(DownloadForOfflineOutlinedIcon);
            break;
        case "approval_log":
            icon = React.createElement(CheckCircleOutlineIcon);
            break;
        case "Planning Roll Up":
            icon = React.createElement(ArrowCircleUpIcon);
            break;
        default:
            icon = React.createElement(PolicyIcon);
            break;
    }
    return icon;
};

class Option {
    constructor(screen, isChildren = false) {
        this.key = screen.key;
        this.title = screen.title;
        this.link = screen.link;

        if (!isChildren) {
            this.icon = getIcon(screen.key);
            this.children = [];
        }
    }
}

function frameSideBarOption(screens, isChildren = false) {
    const options = [];

    screens?.forEach((screen) => {
        const newOption = new Option(screen, isChildren);

        if (!isChildren) {
            newOption.children = frameSideBarOption(screen.children, true);
        }

        options.push(newOption);
    });

    return options;
}

const Layout = (props) => {
    const [sideBarOptions, setSideBarOptions] = useState();
    const { navigateAfterUnsavedEdit } = useSelector((state) => state.global);
    const dispatch = useDispatch();

    useEffect(() => {
        const isAuthenticated = localStorage.getItem("token") || null;

        if (!isAuthenticated) {
            return <Navigate to="/" replace />;
        }
    }, []);

    useEffect(() => {
        if (props.navConfig && !_.isEmpty(props.navConfig)) {
            const options = frameSideBarOption(props.navConfig);

            setSideBarOptions(options);
        }
    }, [props.navConfig]);

    return (
        <div className="wrapper">
            <SideBar options={sideBarOptions} />
            <Modal
                open={!!navigateAfterUnsavedEdit}
                aria-labelledby="delete-market"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div className="layout-modal">
                    <div>
                        Are you sure you want to navigate away? There might be
                        unsaved changes
                    </div>
                    <div className="layout-modal-buttons button-group-flex">
                        <Button
                            variant="contained"
                            onClick={() => {
                                setTimeout(() => navigateAfterUnsavedEdit(), 0);
                                dispatch(setTriggerUnsavedEdit(null));
                                dispatch(setNavigateAfterUnsavedEdit(null));
                            }}
                        >
                            Proceed
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                dispatch(setNavigateAfterUnsavedEdit(null));
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </Modal>
            <div className="layout">
                <Header title="Initial Pricing" />
                <div className="main-content" id="content">
                    <LoadingOverlay showLoader={props.loading !== 0} />
                    <div
                        style={{
                            position: "relative",
                            width: "100%",
                            height: "100%",
                            boxSizing: "border-box",
                            overflow: "auto",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <UpdateBanner />
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (store) => {
    return {
        navConfig: store.global.navConfig,
        loading: store.global.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
