/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */
import { createSlice } from "@reduxjs/toolkit";
import { map } from "lodash";
import { API } from "../../../utils/axios/index";
import { setErrorMessage } from "../../../utils/helpers/utility_helpers";
import { setFilteredData } from "../filters/filters";

const initialState = {
    loading: 0,
    compLoading: {},
    lengthyOpLoader: false,
    messageType: null,
    message: "",
    fiscalCalendar: [],
    disableDataLoadScreen: [],
    productConfigurationLevel: {},
    screenConfig: {},
    elementLabels: {},
    globalMetrics: [],
    navConfig: {},
    sseCompletedEvents: [],
    updatedTime: {},
    notifications: [],
    notificationsFetchCount: 0,
    newNotificationsCount: 0,
    userScreens: {}, // By default access to all screens - change to none later
    userPermissions: null, // No access to actions by default - Change to null

    extraBreadcrumbPath: [],
    triggerUnsavedEdit: null,
    navigateAfterUnsavedEdit: null,

    // next update
    nextUpdateTime: null,
};

const globalSlice = createSlice({
    name: "global",
    initialState,
    reducers: {
        requestStart(state, action) {
            state.loading += 1;
            // state.error = false;
        },
        requestComplete(state, action) {
            if (state.loading > 0) state.loading -= 1;

            if (action.payload) {
                state.message = action.payload.successMessage || action.payload;
                state.messageType = action.payload.success ? "success" : "info";
            }
        },
        requestFail(state, action) {
            const error =
                action.payload || "Something went wrong. Please try again";
            if (state.loading > 0) state.loading -= 1;
            state.messageType = "error";
            state.message = error;
        },
        disableLoader(state, action) {
            state.loading = 0;
        },
        requestStartNoLoader(state, action) {
            // state.error = false;
        },
        requestCompleteNoLoader(state, action) {
            if (action.payload) {
                state.messageType = "info";
                state.message = action.payload;
            }
        },
        requestFailNoLoader(state, action) {
            const error =
                action.payload || "Something went wrong. Please try again";
            state.message = error;
            state.messageType = "error";
        },
        requestCompStart(state, action) {
            state.compLoading[action.payload] =
                (state.compLoading[action.payload] || 0) + 1;
        },
        requestCompComplete(state, action) {
            state.compLoading[action.payload] -= 1;
        },
        requestCompFail(state, action) {
            const error =
                action.payload.message ||
                "Something went wrong. Please try again";
            state.compLoading[action.payload.comp] -= 1;
            state.messageType = "error";
            state.message = error;
        },
        requestCompReset(state, action) {
            state.compLoading[action.payload] = 0;
        },
        toastError(state, action) {
            const error =
                action.payload || "Something went wrong. Please try again";
            state.messageType = "error";
            state.message = error;
        },
        toastMessage(state, action) {
            const message = action.payload || "Action successful";
            state.messageType = "info";
            state.message = message;
        },
        toastSuccessMessage(state, action) {
            const message = action.payload || "Action successful";
            state.messageType = "success";
            state.message = message;
        },
        toastWarningMessage(state, action) {
            const message = action.payload || "Warning";
            state.messageType = "warning";
            state.message = message;
        },
        resetToastMessage(state, action) {
            state.message = "";
            state.messageType = null;
        },
        setScreenDisableDataLoad(state, action) {
            state.disableDataLoadScreen = action.payload;
        },
        setProductConfigurationLevel(state, action) {
            state.productConfigurationLevel = action.payload;
        },
        setFiscalCalendar(state, action) {
            state.fiscalCalendar = action.payload;
        },
        setScreenConfig(state, action) {
            state.screenConfig = action.payload;
        },
        setElementLabels(state, action) {
            state.elementLabels = action.payload;
        },
        setMetrics(state, action) {
            state.globalMetrics = action.payload;
        },
        setNavigationConfig(state, action) {
            state.navConfig = action.payload;
        },
        sseCompleteEvents(state, action) {
            state.sseCompletedEvents = action.payload;
        },
        clearCompletedEvents(state, action) {
            state.sseCompletedEvents = [];
        },
        setUpdatedTime(state, action) {
            state.updatedTime = action.payload;
        },
        setNotifications(state, action) {
            state.notifications = action.payload;
        },
        setUserScreens(action, payload) {
            state.userScreens = action.payload;
        },
        setUserPermissions(state, action) {
            state.userPermissions = action.payload;
        },
        toggleLengthyOpLoader(state, action) {
            state.lengthyOpLoader = action.payload;
        },
        notificationsFetchStart(state) {
            state.notificationsFetchCount += 1;
        },
        notificationsFetchStop(state) {
            state.notificationsFetchCount -= 1;
        },
        setNewNotificationsCount(state, action) {
            state.newNotificationsCount += action.payload;
        },
        resetNewNotificationsCount(state) {
            state.newNotificationsCount = 0;
        },
        setExtraBreadcrumbPath(state, action) {
            state.extraBreadcrumbPath = action.payload;
        },
        setTriggerUnsavedEdit(state, action) {
            window.onbeforeunload = action.payload
                ? function (e) {
                      e.preventDefault();
                      e.returnValue = true;
                  }
                : null;

            state.triggerUnsavedEdit = action.payload;
        },
        setNavigateAfterUnsavedEdit(state, action) {
            state.navigateAfterUnsavedEdit = action.payload;
        },
        setNextUpdateTime(state, action) {
            state.nextUpdateTime = action.payload;
        },
        resetNextUpdateTime(state) {
            state.nextUpdateTime = null;
        },
    },
});

export const {
    requestStart,
    requestComplete,
    requestFail,
    requestStartNoLoader,
    requestCompleteNoLoader,
    requestFailNoLoader,
    requestCompStart,
    requestCompComplete,
    requestCompFail,
    requestCompReset,
    toastError,
    toastMessage,
    toastSuccessMessage,
    toastWarningMessage,
    resetToastMessage,
    setProductConfigurationLevel,
    setScreenDisableDataLoad,
    setFiscalCalendar,
    setScreenConfig,
    setElementLabels,
    setNavigationConfig,
    sseCompleteEvents,
    clearCompletedEvents,
    setNotifications,
    disableLoader,
    setUserScreens,
    setUserPermissions,
    setUpdatedTime,
    toggleLengthyOpLoader,
    setMetrics,
    notificationsFetchStart,
    notificationsFetchStop,
    setNewNotificationsCount,
    resetNewNotificationsCount,
    setExtraBreadcrumbPath,
    setTriggerUnsavedEdit,
    setNavigateAfterUnsavedEdit,
    setNextUpdateTime,
    resetNextUpdateTime,
} = globalSlice.actions;

export default globalSlice.reducer;

export const getNextUpdateTime = () => (dispatch) => {
    return API.get("/fetch_deployment_dates")
        .then((res) => {
            if (res.data?.data?.length) {
                dispatch(setNextUpdateTime(res.data.data?.[0]));
                localStorage.setItem(
                    "nextUpdateTime",
                    JSON.stringify(res.data.data?.[0])
                );
                return true;
            }
            return false;
        })
        .catch((err) => {
            dispatch(toastError("Error while fetching next update time"));
        });
};

export const getGlobalConfig =
    ({ screen }) =>
    (dispatch, getState) => {
        dispatch(requestStart());
        let data = _.cloneDeep(abcd.data);

        let uiConfigAllScreen = data && data.UI_CONFIG_ALL_SCREEN;

        let elementLabels =
            data &&
            data.UI_CONFIG_COMMON_IN_ALL &&
            data.UI_CONFIG_COMMON_IN_ALL.element_labels;

        let navConfig =
            data &&
            data.UI_CONFIG_COMMON_IN_ALL &&
            data.UI_CONFIG_COMMON_IN_ALL.nav_config;

        let metrics_ui =
            data &&
            data.UI_CONFIG_COMMON_IN_ALL &&
            data.UI_CONFIG_COMMON_IN_ALL.metrics_ui;

        let landingScreen =
            screen ||
            (data &&
                data.UI_CONFIG_COMMON_IN_ALL &&
                data.UI_CONFIG_COMMON_IN_ALL.landing_screen) ||
            "DECISION_DASHBOARD";
        let options = uiConfigAllScreen && uiConfigAllScreen[landingScreen];

        let filterList =
            options && options.filter_options ? options.filter_options : [];

        let filtersData = {};
        filterList &&
            map(filterList, (o) => {
                filtersData[o.type] = o.type.includes("dateRange")
                    ? { startDate: null, endDate: null, weeks: [] }
                    : {
                          options: [],
                          selectedItems: [],
                          selectedItemsArray: [],
                      };
            });
        let productConfigurationLevel = (data.UI_CONFIG_COMMON_IN_ALL &&
            data.UI_CONFIG_COMMON_IN_ALL.product_configuration_level) || {
            name: "Item",
            type: "something",
        };
        dispatch(setProductConfigurationLevel(productConfigurationLevel));
        dispatch(setElementLabels(elementLabels));
        dispatch(setMetrics(metrics_ui));
        dispatch(
            setFilteredData({
                payload: filtersData,
                activeScreen: landingScreen,
            })
        );
        dispatch(setScreenConfig(uiConfigAllScreen));
        dispatch(setNavigationConfig(navConfig));
        dispatch(requestComplete());
    };

export const removeLoader = () => (dispatch, getState) => {
    const loading = getState().global.loading;
    if (loading !== 0) {
        dispatch(disableLoader());
    }
};

export const getFiscalCalendar = (payload) => (dispatch, getState) => {
    dispatch(requestStart());
    return API.post("/fiscal-calendar", payload)
        .then((response) => {
            if (response.data && response.data.status === 200) {
                const { data } = response.data;
                // const fiscalCalendarData = formatCalendarData(data);
                dispatch(setFiscalCalendar(data));
                dispatch(requestComplete());
                return data;
            } else {
                dispatch(requestFail());
                return false;
            }
        })
        .catch((error) => {
            console.log(error);
            const errorMessage = setErrorMessage(error);
            dispatch(requestFail(errorMessage));
            return false;
        });
};

export const getUserScreens = () => (dispatch, getState) => {
    dispatch(requestStart());
    API.get("/user-management/screens/")
        .then((response) => {
            if (response.data && response.data.status === true) {
                const { data } = response.data;
                dispatch(setUserScreens(data));
                dispatch(requestComplete());
            }
        })
        .catch((error) => {
            console.log(error);
            const errorMessage = setErrorMessage(error);
            dispatch(requestFail(errorMessage));
        });
};

export const getUserHierarchy = (screen) => (dispatch, getState) => {
    dispatch(requestStart());
    API.get("/user-management/screen-hierarchy?app=pricesmart&screen=" + screen)
        .then((response) => {
            if (response.data && response.data.status === true) {
                const { data } = response.data;
                if (data) {
                    dispatch(setUserPermissions(data));
                }
                dispatch(requestComplete());
            }
        })
        .catch((error) => {
            console.log(error);
            const errorMessage = setErrorMessage(error);
            dispatch(requestFail(errorMessage));
        });
};

let abcd = {
    data: {
        UI_CONFIG_ALL_SCREEN: {
            EU_PRICE_FILE_HISTORY: {
                table_config: {
                    eu_price_file_history: {
                        table_header: [
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "",
                                key: "",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: false,
                                filter: false,
                                pinned: true,
                                lockPinned: true,
                                checkbox_selection: true,
                                row_group: false,
                                width: 50,
                                cellStyle: {
                                    background: "#f7f7f7",
                                },
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Price Files",
                                key: "priceFile",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: false,
                                filter: true,
                                pinned: true,
                                lockPinned: true,
                                checkbox_selection: false,
                                row_group: false,
                                cellStyle: {
                                    background: "#f7f7f7",
                                },
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Market Season",
                                key: "marketSeason",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Uploaded Date",
                                key: "uploadedDate",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Uploaded By",
                                key: "uploadedBy",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                        ],
                    },
                },
            },
            MSRP_TABLE: {
                table_config: {
                    msrp_table: {
                        table_header: [
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "MSRP",
                                key: "msrp",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: false,
                                filter: true,
                                pinned: true,
                                lockPinned: true,
                                checkbox_selection: false,
                                row_group: false,
                                cellStyle: {
                                    background: "#f7f7f7",
                                },
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Date Of Change",
                                key: "marketSeason",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Made By",
                                key: "madeBy",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Valid From",
                                key: "validFrom",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Valid To",
                                key: "validTo",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Comment",
                                key: "comment",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                        ],
                    },
                },
            },
            WORKBENCH_VIEW_PLAN_GROUP: {
                table_config: {
                    workbench_view_plan_group_table: {
                        table_header: [
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "PLAN GROUP",
                                key: "planGroupName",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: false,
                                filter: true,
                                pinned: true,
                                lockPinned: true,
                                checkbox_selection: false,
                                row_group: false,
                                cellStyle: {
                                    background: "#f7f7f7",
                                },
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "BRAND GROUP",
                                key: "brandGroup",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "MERCH DIVISION",
                                key: "merchandiseDivision",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "MERCH ORG",
                                key: "merchandiseOrganisation",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "GENDER",
                                key: "gender",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "BRAND",
                                key: "brand",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "MERCH SEGMENT",
                                key: "merchandiseSegment",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "MERCH SIZE GROUP",
                                key: "merchandiseSizeGroup",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "MERCH CATEGORY",
                                key: "merchandiseCategory",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "PRODUCT CATEGORY",
                                key: "productCategory",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "PRODUCT CLASS",
                                key: "productClass",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "PRODUCT SUBCLASS",
                                key: "productSubclass",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                        ],
                    },
                },
            },
            VIEW_PRICE_RULE_TABLE: {
                table_config: {
                    view_price_rule_table: {
                        table_header: [
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Rule Name",
                                key: "rule_name",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: true,
                                hideHeaderCheckbox: true,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Market Indicator",
                                key: "market_id",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                hideHeaderCheckbox: true,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Last Revised by",
                                key: "updated_by",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                hideHeaderCheckbox: true,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Last Revised Date",
                                key: "updated_at",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                hideHeaderCheckbox: true,
                            },
                        ],
                    },
                },
            },
            VIEW_PLAN_GROUP_TABLE: {
                table_config: {
                    view_plan_group_table: {
                        table_header: [
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "",
                                key: "",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: false,
                                filter: false,
                                pinned: true,
                                lockPinned: true,
                                checkbox_selection: true,
                                row_group: false,
                                width: 50,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Plan Group",
                                key: "name",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: true,
                                lockPinned: true,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Region",
                                key: "region",
                                action: "plan_group_region",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                                filterValueGetter: (data) =>
                                    data.getValue("region").label,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Channel",
                                key: "channel",
                                action: "plan_group_channel",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                                filterValueGetter: (data) =>
                                    data
                                        .getValue("channel")
                                        ?.map((obj) => obj.label)
                                        .join(",") ?? "",
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Merch Div",
                                key: "merchandise_hierarchy",
                                action: "plan_group_merch_div",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                                filterValueGetter: (data) =>
                                    data
                                        .getValue("merchandise_hierarchy")
                                        ?.["product_h1"]?.map(
                                            (obj) => obj.label
                                        )
                                        .join(",") ?? "",
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Merch Org",
                                key: "merchandise_hierarchy",
                                action: "plan_group_merch_org",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                                filterValueGetter: (data) =>
                                    data
                                        .getValue("merchandise_hierarchy")
                                        ?.["product_h2"]?.map(
                                            (obj) => obj.label
                                        )
                                        .join(",") ?? "",
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Brand",
                                key: "merchandise_hierarchy",
                                action: "plan_group_brand",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                                filterValueGetter: (data) =>
                                    data
                                        .getValue("merchandise_hierarchy")
                                        ?.["product_h5"]?.map(
                                            (obj) => obj.label
                                        )
                                        .join(",") ?? "",
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Prod Cat",
                                key: "product_hierarchy",
                                action: "plan_group_prod_cat",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                                filterValueGetter: (data) =>
                                    data
                                        .getValue("product_hierarchy")
                                        ?.["product_h9"]?.map(
                                            (obj) => obj.label
                                        )
                                        .join(",") ?? "",
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Prod Class",
                                key: "product_hierarchy",
                                action: "plan_group_prod_class",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                                filterValueGetter: (data) =>
                                    data
                                        .getValue("product_hierarchy")
                                        ?.["product_h10"]?.map(
                                            (obj) => obj.label
                                        )
                                        .join(",") ?? "",
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Prod Subclass",
                                key: "product_hierarchy",
                                action: "plan_group_prod_subclass",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                                filterValueGetter: (data) =>
                                    data
                                        .getValue("product_hierarchy")
                                        ?.["product_h11"]?.map(
                                            (obj) => obj.label
                                        )
                                        .join(",") ?? "",
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Tagged Users",
                                key: "tagged_user",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                        ],
                    },
                },
            },
            VIEW_ALERT_TABLE: {
                table_config: {
                    view_alert_table: {
                        table_header: [
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "",
                                key: "",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: false,
                                filter: false,
                                pinned: true,
                                lockPinned: true,
                                checkbox_selection: true,
                                row_group: false,
                                width: 50,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Alert",
                                key: "name",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Description",
                                key: "description",
                                action: null,
                                // action: "plan_group_region",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Region",
                                key: "region",
                                action: "alert_exception_region",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Channel",
                                key: "channel",
                                action: "alert_exception_channel",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Threshold",
                                key: "threshold",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Enable",
                                key: "is_enabled",
                                action: "alert_exception_enabled",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Mandatory",
                                key: "is_mandatory",
                                action: "alert_exception_mandatory",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                        ],
                    },
                },
            },
            VIEW_EXCEPTION_TABLE: {
                table_config: {
                    view_exception_table: {
                        table_header: [
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "",
                                key: "",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: false,
                                filter: false,
                                pinned: true,
                                lockPinned: true,
                                checkbox_selection: true,
                                row_group: false,
                                width: 50,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Exception",
                                key: "name",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Description",
                                key: "description",
                                action: null,
                                // action: "plan_group_region",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Region",
                                key: "region",
                                action: "alert_exception_region",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Channel",
                                key: "channel",
                                action: "alert_exception_channel",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Threshold",
                                key: "threshold",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Enable",
                                key: "is_enabled",
                                action: "alert_exception_enabled",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Mandatory",
                                key: "is_mandatory",
                                action: "alert_exception_mandatory",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                        ],
                    },
                },
            },
            SELECT_FILTERS_REGION_CHANNEL: {
                filter_options: [
                    {
                        type: "region",
                        is_mandatory: false,
                        is_multiple_selection: false,
                        filter_endpoint: "/regions",
                        filter_endpoint_method: "get",
                        filter_type: "region_hierarchy",
                    },
                    {
                        type: "channel",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/channels",
                        filter_endpoint_method: "get",
                        filter_type: "region_hierarchy",
                    },
                ],
            },
            SELECT_FILTERS_ATTRIBUTES: {
                filter_options: [
                    {
                        type: "season_code",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "season_desc",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "sap_material_status",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "ip_price_export_status",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "gfe_material_type_ind",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "gfe_material_status",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                ],
            },
            SELECT_FILTERS_BRAND_GROUP: {
                filter_options: [
                    {
                        type: "product_h4",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                ],
            },
            CREATE_PLAN_GROUP_BRAND_GROUP: {
                filter_options: [
                    {
                        type: "product_h4",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                ],
            },
            SELECT_FILTERS_MERCH_DIVISION: {
                filter_options: [
                    {
                        type: "product_h1",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                ],
            },
            CREATE_PLAN_GROUP_MERCH_DIVISION: {
                filter_options: [
                    {
                        type: "product_h1",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                ],
            },
            SELECT_FILTERS_MERCH: {
                filter_options: [
                    {
                        type: "product_h2",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "product_h3",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "product_h5",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "product_h6",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "product_h7",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "product_h8",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                ],
            },
            // SELECT_FILTERS_MERCH: {
            //     filter_options: [
            //         {
            //             type: "product_h1",
            //             is_mandatory: false,
            //             is_multiple_selection: true,
            //             filter_endpoint: "/filters",
            //             filter_endpoint_method: "post",
            //             filter_type: "product_hierarchy",
            //         },
            //         {
            //             type: "product_h2",
            //             is_mandatory: false,
            //             is_multiple_selection: true,
            //             filter_endpoint: "/filters",
            //             filter_endpoint_method: "post",
            //             filter_type: "product_hierarchy",
            //         },
            //         {
            //             type: "product_h3",
            //             is_mandatory: false,
            //             is_multiple_selection: true,
            //             filter_endpoint: "/filters",
            //             filter_endpoint_method: "post",
            //             filter_type: "product_hierarchy",
            //         },
            //         {
            //             type: "product_h4",
            //             is_mandatory: false,
            //             is_multiple_selection: true,
            //             filter_endpoint: "/filters",
            //             filter_endpoint_method: "post",
            //             filter_type: "product_hierarchy",
            //         },
            //         {
            //             type: "product_h5",
            //             is_mandatory: false,
            //             is_multiple_selection: true,
            //             filter_endpoint: "/filters",
            //             filter_endpoint_method: "post",
            //             filter_type: "product_hierarchy",
            //         },
            //         {
            //             type: "product_h6",
            //             is_mandatory: false,
            //             is_multiple_selection: true,
            //             filter_endpoint: "/filters",
            //             filter_endpoint_method: "post",
            //             filter_type: "product_hierarchy",
            //         },
            //         {
            //             type: "product_h7",
            //             is_mandatory: false,
            //             is_multiple_selection: true,
            //             filter_endpoint: "/filters",
            //             filter_endpoint_method: "post",
            //             filter_type: "product_hierarchy",
            //         },
            //         {
            //             type: "product_h8",
            //             is_mandatory: false,
            //             is_multiple_selection: true,
            //             filter_endpoint: "/filters",
            //             filter_endpoint_method: "post",
            //             filter_type: "product_hierarchy",
            //         },
            //     ],
            // },
            CREATE_PLAN_GROUP_MERCH: {
                filter_options: [
                    {
                        type: "product_h2",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "product_h3",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "product_h5",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "product_h6",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "product_h7",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "product_h8",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                ],
            },
            SELECT_FILTERS_PRODUCT: {
                filter_options: [
                    {
                        type: "product_h9",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "product_h10",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "product_h11",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                ],
            },
            CREATE_PLAN_GROUP_PRODUCT: {
                filter_options: [
                    {
                        type: "product_h9",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "product_h10",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "product_h11",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                ],
            },
            SELECT_FILTERS_PLANNING: {
                filter_options: [
                    {
                        type: "product_h12",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "product_h13",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "product_h14",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "product_h15",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "product_h16",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                ],
            },
            CREATE_PLAN_GROUP_PLANNING: {
                filter_options: [
                    {
                        type: "product_h12",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "product_h13",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "product_h14",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "product_h15",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "product_h16",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_endpoint_method: "post",
                        filter_type: "product_hierarchy",
                    },
                ],
            },
            DECISION_DASHBOARD: {
                ui_elements: {
                    metrics: [
                        {
                            key: "gross_margin",
                            display: "GM$",
                        },
                        {
                            key: "sales_units",
                            display: "Sales Units",
                        },
                        {
                            key: "sales_dollars",
                            display: "Sales $",
                        },
                    ],
                    table_setup: [
                        {
                            key: "total",
                            display: "Total",
                        },
                        {
                            key: "reg",
                            display: "Reg Price",
                            default: true,
                        },
                        {
                            key: "clearance",
                            display: "Clearance",
                        },
                    ],
                },
                filter_options: [
                    {
                        type: "product_h1",
                        is_mandatory: true,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "product_h2",
                        is_mandatory: true,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_type: "product_hierarchy",
                    },
                ],
                table_config: {
                    decision_dashboard_promo_week_table: {
                        table_header: [
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Offers",
                                key: "promo_name",
                                action: "inlineEditCell",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: true,
                                hideHeaderCheckbox: false,
                                row_group: true,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Start Date",
                                key: "start_date",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "End Date",
                                key: "end_date",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Performance",
                                key: "performance",
                                action: "performance",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "IA Recommended", //projected
                                key: "ia_recommended",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Finalized Discount",
                                key: "discount",
                                action: "show_type_and_value",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Products",
                                key: "product_count",
                                action: "textButton",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Stores",
                                key: "store_count",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Units Per Txn",
                                key: "unit_per_txn",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "# of Txn",
                                key: "no_of_txn",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                hidden: false,
                                header: "Markdown Budget",
                                columns: [
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: " Projected",
                                        key: "markdown_projected",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Incremental",
                                        key: "markdown_incremenatl",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                ],
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                hidden: false,
                                header: "Sales Units",
                                columns: [
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Baseline",
                                        key: "sales_units_baseline",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Target",
                                        key: "sales_units_target",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "IA Projected",
                                        key: "sales_units_ia_recommended",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Incremental",
                                        key: "sales_units_ia_incremental",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                ],
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                hidden: false,
                                header: "Revenue",
                                columns: [
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Baseline",
                                        key: "sales_dollars_baseline",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Target",
                                        key: "sales_dollars_target",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "IA Projected",
                                        key: "sales_dollars_ia_recommended",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Incremental",
                                        key: "sales_dollars_ia_incremental",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                ],
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                hidden: false,
                                header: "Margin",
                                columns: [
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Baseline",
                                        key: "gross_margin_baseline",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Target",
                                        key: "gross_margin_target",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "IA Projected",
                                        key: "gross_margin_ia_recommended",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Incremental",
                                        key: "gross_margin_ia_incremental",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                ],
                            },
                        ],
                        add_metrics_new: [
                            {
                                label: "Margin",
                                value: "gm_dollars",
                                metric_name: "Metrics",
                                metric_type: "drop_down",
                                columns_included: [
                                    "gross_margin_baseline",
                                    "gross_margin_target",
                                    "gross_margin_ia_recommended",
                                    "gross_margin_ia_incremental",
                                ],
                            },
                            {
                                label: "Sales Units",
                                value: "sales_units",
                                metric_name: "Metrics",
                                metric_type: "drop_down",
                                columns_included: [
                                    "sales_units_baseline",
                                    "sales_units_target",
                                    "sales_units_ia_recommended",
                                    "sales_units_ia_incremental",
                                ],
                            },
                            {
                                label: "Revenue",
                                value: "sales_dollars",
                                metric_name: "Metrics",
                                metric_type: "drop_down",
                                columns_included: [
                                    "sales_dollars_baseline",
                                    "sales_dollars_target",
                                    "sales_dollars_ia_recommended",
                                    "sales_dollars_ia_incremental",
                                ],
                            },
                        ],
                        add_metrics: {},
                        offer_type_options: {
                            percent_off: "% Off",
                            extra_amount_off: "$ Off",
                            fixed_price: "PP",
                            bxgy_fixed_price: "BXGY",
                            bmsm_percent_off: "BMSM %",
                            bmsm_fixed_price: "BMSM $",
                        },
                    },
                    decision_dashboard_events_promo_table: {
                        table_header: [
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Event Name",
                                key: "event_name",
                                action: null, //"event_name",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: true,
                                // row_span: true
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Offers",
                                key: "promo_name",
                                action: null, //"inlineEditCell",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                hideHeaderCheckbox: false,
                                row_group: true,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Start Date",
                                key: "start_date",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "End Date",
                                key: "end_date",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Performance",
                                key: "performance",
                                action: "performance",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "IA Recommended", //projected
                                key: "ia_recommended",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Finalized Discount",
                                key: "discount",
                                action: "show_type_and_value",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Products",
                                key: "product_count",
                                action: "textButton",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Stores",
                                key: "store_count",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Units Per Txn",
                                key: "unit_per_txn",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "# of Txn",
                                key: "no_of_txn",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                hidden: false,
                                header: "Markdown Budget",
                                columns: [
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: " Projected",
                                        key: "markdown_projected",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Incremental",
                                        key: "markdown_incremenatl",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                ],
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                hidden: false,
                                header: "Sales Units",
                                columns: [
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Baseline",
                                        key: "sales_units_baseline",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Target",
                                        key: "sales_units_target",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "IA Projected",
                                        key: "sales_units_ia_recommended",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Incremental",
                                        key: "sales_units_ia_incremental",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                ],
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                hidden: false,
                                header: "Revenue",
                                columns: [
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Baseline",
                                        key: "sales_dollars_baseline",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Target",
                                        key: "sales_dollars_target",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "IA Projected",
                                        key: "sales_dollars_ia_recommended",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Incremental",
                                        key: "sales_dollars_ia_incremental",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                ],
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                hidden: false,
                                header: "Margin",
                                columns: [
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Baseline",
                                        key: "gross_margin_baseline",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Target",
                                        key: "gross_margin_target",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "IA Projected",
                                        key: "gross_margin_ia_recommended",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Incremental",
                                        key: "gross_margin_ia_incremental",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                ],
                            },
                        ],
                        add_metrics_new: [
                            {
                                label: "Margin",
                                value: "gm_dollars",
                                metric_name: "Metrics",
                                metric_type: "drop_down",
                                columns_included: [
                                    "gross_margin_baseline",
                                    "gross_margin_target",
                                    "gross_margin_ia_recommended",
                                    "gross_margin_ia_incremental",
                                ],
                            },
                            {
                                label: "Sales Units",
                                value: "sales_units",
                                metric_name: "Metrics",
                                metric_type: "drop_down",
                                columns_included: [
                                    "sales_units_baseline",
                                    "sales_units_target",
                                    "sales_units_ia_recommended",
                                    "sales_units_ia_incremental",
                                ],
                            },
                            {
                                label: "Revenue",
                                value: "sales_dollars",
                                metric_name: "Metrics",
                                metric_type: "drop_down",
                                columns_included: [
                                    "sales_dollars_baseline",
                                    "sales_dollars_target",
                                    "sales_dollars_ia_recommended",
                                    "sales_dollars_ia_incremental",
                                ],
                            },
                        ],
                        add_metrics: {},
                    },
                    decision_dashboard_events_week_table: {
                        table_header: [
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Event Name",
                                key: "event_name",
                                action: null, //"event_name",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: true,
                                // row_span: true
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Start Date",
                                key: "start_date",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "End Date",
                                key: "end_date",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Performance",
                                key: "performance",
                                action: "performance",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "IA Recommended", //projected
                                key: "ia_recommended",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Finalized Discount",
                                key: "discount",
                                action: "show_type_and_value",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Products",
                                key: "product_count",
                                action: "textButton",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Stores",
                                key: "store_count",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Units Per Txn",
                                key: "unit_per_txn",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "# of Txn",
                                key: "no_of_txn",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                hidden: false,
                                header: "Markdown Budget",
                                columns: [
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: " Projected",
                                        key: "markdown_projected",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Incremental",
                                        key: "markdown_incremenatl",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                ],
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                hidden: false,
                                header: "Sales Units",
                                columns: [
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Baseline",
                                        key: "sales_units_baseline",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Target",
                                        key: "sales_units_target",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "IA Projected",
                                        key: "sales_units_ia_recommended",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Incremental",
                                        key: "sales_units_ia_incremental",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                ],
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                hidden: false,
                                header: "Revenue",
                                columns: [
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Baseline",
                                        key: "sales_dollars_baseline",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Target",
                                        key: "sales_dollars_target",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "IA Projected",
                                        key: "sales_dollars_ia_recommended",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Incremental",
                                        key: "sales_dollars_ia_incremental",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                ],
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                hidden: false,
                                header: "Margin",
                                columns: [
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Baseline",
                                        key: "gross_margin_baseline",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Target",
                                        key: "gross_margin_target",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "IA Projected",
                                        key: "gross_margin_ia_recommended",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Incremental",
                                        key: "gross_margin_ia_incremental",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                ],
                            },
                        ],
                        add_metrics_new: [
                            {
                                label: "Margin",
                                value: "gm_dollars",
                                metric_name: "Metrics",
                                metric_type: "drop_down",
                                columns_included: [
                                    "gross_margin_baseline",
                                    "gross_margin_target",
                                    "gross_margin_ia_recommended",
                                    "gross_margin_ia_incremental",
                                ],
                            },
                            {
                                label: "Sales Units",
                                value: "sales_units",
                                metric_name: "Metrics",
                                metric_type: "drop_down",
                                columns_included: [
                                    "sales_units_baseline",
                                    "sales_units_target",
                                    "sales_units_ia_recommended",
                                    "sales_units_ia_incremental",
                                ],
                            },
                            {
                                label: "Revenue",
                                value: "sales_dollars",
                                metric_name: "Metrics",
                                metric_type: "drop_down",
                                columns_included: [
                                    "sales_dollars_baseline",
                                    "sales_dollars_target",
                                    "sales_dollars_ia_recommended",
                                    "sales_dollars_ia_incremental",
                                ],
                            },
                        ],
                        add_metrics: {},
                    },
                    decision_dashboard_product_details_table: {
                        table_header: [
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Product Name",
                                key: "product_name",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                hideHeaderCheckbox: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Base Price",
                                key: "base_price",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Class",
                                key: "class",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Sub Class",
                                key: "sub_class",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                        ],
                        add_metrics: {},
                    },
                },
            },
            PRICE_LIST_CONFIG: {
                table_config: {
                    price_list_table: {
                        table_header: [
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "S No",
                                key: "id",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                hideHeaderCheckbox: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Region",
                                key: "region",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                hideHeaderCheckbox: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Channel",
                                key: "channel",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                hideHeaderCheckbox: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Pricelist Name",
                                key: "pricelistName",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                hideHeaderCheckbox: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Currency",
                                key: "currency",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: false,
                                hideHeaderCheckbox: false,
                            },
                        ],
                    },
                },
            },
            CREATE_PROMO_SELECT_PRODUCTS: {
                filter_options: [
                    {
                        type: "product_h1",
                        is_mandatory: true,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "product_h2",
                        is_mandatory: true,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "product_h3",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "product_h4",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "brand",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_type: "product_hierarchy",
                    },
                ],
                table_config: {
                    product_table: {
                        table_header: [
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Hero SKU",
                                key: "is_hero",
                                action: "hero_sku",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: true,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "SKU Id",
                                key: "product_h5_id",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Description",
                                key: "product_h5_name",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Base Price",
                                key: "price",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                        ],
                        add_metrics: {},
                    },
                },
            },
            CREATE_PROMO_SELECT_STORES: {
                filter_options: [
                    {
                        type: "store_h1",
                        is_mandatory: true,
                        is_multiple_selection: false,
                        filter_endpoint: "/filters",
                        filter_type: "store_hierarchy",
                    },
                    {
                        type: "store_h2",
                        is_mandatory: true,
                        is_multiple_selection: false,
                        filter_endpoint: "/filters",
                        filter_type: "store_hierarchy",
                    },
                    {
                        type: "store_h3",
                        is_mandatory: true,
                        is_multiple_selection: false,
                        filter_endpoint: "/filters",
                        filter_type: "store_hierarchy",
                    },
                    {
                        type: "store_h5",
                        is_mandatory: true,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_type: "store_hierarchy",
                    },
                ],
            },
            PROMO_SIMULATOR: {
                table_config: {
                    scenario_table: {
                        table_header: [
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Id",
                                key: "id",
                                action: null,
                                hidden: true,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Name",
                                key: "name",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Price",
                                key: "price",
                                action: null,
                                hidden: true,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "IA Recommended",
                                key: "ia_recommended",
                                action: "finalize_edit",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Scenario 1",
                                key: "scenario_1",
                                action: "finalize_edit",
                                hidden: true,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Scenario 2",
                                key: "scenario_2",
                                action: "finalize_edit",
                                hidden: true,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                        ],
                        add_metrics: {},
                    },
                    scenario_details_table: {
                        table_header: [
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Id",
                                key: "id",
                                action: null,
                                hidden: true,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Name",
                                key: "hierarchy_name",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                hidden: false,
                                header: "Margin",
                                columns: [
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "IA Recommended",
                                        key: "margin_ia_recommended",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Scenario 1",
                                        key: "margin_scenario_1",
                                        action: null,
                                        hidden: true,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Scenario 2",
                                        key: "margin_scenario_2",
                                        action: null,
                                        hidden: true,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                ],
                            },

                            {
                                type: "static_column",
                                mapping: "data",
                                hidden: false,
                                header: "Revenue",
                                columns: [
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "IA Recommended",
                                        key: "sales_ia_recommended",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Scenario 1",
                                        key: "sales_scenario_1",
                                        action: null,
                                        hidden: true,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Scenario 2",
                                        key: "sales_scenario_2",
                                        action: null,
                                        hidden: true,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                ],
                            },

                            {
                                type: "static_column",
                                mapping: "data",
                                hidden: false,
                                header: "Sales Units",
                                columns: [
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "IA Recommended",
                                        key: "sales_units_ia_recommended",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Scenario 1",
                                        key: "sales_units_scenario_1",
                                        action: null,
                                        hidden: true,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Scenario 2",
                                        key: "sales_units_scenario_2",
                                        action: null,
                                        hidden: true,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                ],
                            },

                            {
                                type: "static_column",
                                mapping: "data",
                                hidden: false,
                                header: "Incremental Margin",
                                columns: [
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "IA Recommended",
                                        key: "incremental_margin_ia_recommended",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Scenario 1",
                                        key: "incremental_margin_scenario_1",
                                        action: null,
                                        hidden: true,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Scenario 2",
                                        key: "incremental_margin_scenario_2",
                                        action: null,
                                        hidden: true,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                ],
                            },

                            {
                                type: "static_column",
                                mapping: "data",
                                hidden: false,
                                header: "Incremental Revenue",
                                columns: [
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "IA Recommended",
                                        key: "incremental_sales_ia_recommended",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Scenario 1",
                                        key: "incremental_sales_scenario_1",
                                        action: null,
                                        hidden: true,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Scenario 2",
                                        key: "incremental_sales_scenario_2",
                                        action: null,
                                        hidden: true,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                ],
                            },

                            {
                                type: "static_column",
                                mapping: "data",
                                hidden: true,
                                header: "Incremental Sales Units",
                                columns: [
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "IA Recommended",
                                        key: "incremental_sales_units_ia_recommended",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Scenario 1",
                                        key: "incremental_sales_units_scenario_1",
                                        action: null,
                                        hidden: true,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Scenario 2",
                                        key: "incremental_sales_units_scenario_2",
                                        action: null,
                                        hidden: true,
                                        aggregate: null,
                                        sorting: true,
                                        filter: true,
                                        pinned: false,
                                        row_group: false,
                                    },
                                ],
                            },
                        ],
                        add_metrics: {},
                    },
                },
            },
            PROMO_WORKBENCH: {
                filter_options: [
                    {
                        type: "product_h1",
                        is_mandatory: true,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "product_h2",
                        is_mandatory: true,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "product_h3",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "product_h4",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "dateRange",
                        is_mandatory: false,
                        filter_endpoint: "/",
                        filter_type: "dateRange",
                    },
                ],
                table_config: {
                    promo_workbench_all_promo_table: {
                        table_header: [
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Offer Name",
                                key: "promo_name",
                                action: "promo_name",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                checkbox_selection: true,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Start Date",
                                key: "start_date",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "End Date",
                                key: "end_date",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Offer Status",
                                key: "lifecycle",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Products",
                                key: "products",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Stores",
                                key: "stores",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Performance",
                                key: "performance",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Markdown $",
                                key: "markdown_budget",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Units Target",
                                key: "units_target",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Incremental Units IA Reco",
                                key: "units_incremental_ia",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Incremental Units Finalized",
                                key: "units_incremental_finalized",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Revenue Targets",
                                key: "revenue_target",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Incremental Revenue IA Reco",
                                key: "revenue_incremental_ia",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Incremental Revenue Finalized",
                                key: "revenue_incremental_finalized",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Margin Target",
                                key: "gross_margin_target",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Incremental Margin IA Reco",
                                key: "gross_margin_incremental_ia",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Incremental Margin Finalized",
                                key: "gross_margin_incremental_finalized",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Maximization Metric",
                                key: "maximization_metric",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Discount Type",
                                key: "discount_type",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Discount Range",
                                key: "discount_range",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Discount Level - Products",
                                key: "discount_level",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                        ],
                        add_metrics: {},
                    },
                },
            },
            MARKETING_CALENDAR: {
                filter_options: [
                    {
                        type: "product_h1",
                        is_mandatory: true,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "product_h2",
                        is_mandatory: true,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "product_h3",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_type: "product_hierarchy",
                        hidden: true,
                    },
                    {
                        type: "product_h4",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_type: "product_hierarchy",
                        hidden: true,
                    },
                    {
                        type: "brand",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_type: "product_hierarchy",
                        hidden: true,
                    },
                    {
                        type: "dateRange",
                        is_mandatory: false,
                        filter_endpoint: "/",
                        filter_type: "dateRange",
                    },
                    {
                        type: "events",
                        is_mandatory: false,
                        is_multiple_selection: true,
                        filter_endpoint: "/get-events",
                        filter_type: "events",
                        hidden: true,
                    },
                ],
                ui_elements: {
                    table_setup: [
                        {
                            key: "total",
                            display: "Total",
                        },
                    ],
                },
                table_config: {
                    events_calendar_data: {
                        table_header: [
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Event Name",
                                key: "name",
                                action: "lockUnlock",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: true,
                                lockPinned: true,
                                checkbox_selection: true,
                                row_group: false,
                                width: "300px",
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Start Date",
                                key: "start_date",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "End Date",
                                key: "end_date",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Status",
                                key: "event_lifecycle",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "No. Of Promos",
                                key: "promosCount",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: false,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Customer Segment",
                                key: "customer_segment",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: false,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Performance",
                                key: "event_status",
                                action: "performance",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "#Products",
                                key: "productsCount",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: " #Stores",
                                key: "storeCount",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: false,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                hidden: false,
                                header: "Sales Unit",
                                columns: [
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: " Baseline",
                                        key: "sales_units_baseline",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: " Target",
                                        key: "units_target",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: " IA Projected",
                                        key: "units_ia",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: " Incremental (IA Projected)",
                                        key: "units_incremental_ia",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: " IA Projected (Actualised)",
                                        key: "units_finalized",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                ],
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                hidden: false,
                                header: "Revenue",
                                columns: [
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: " Baseline",
                                        key: "revenue_baseline",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: " Target",
                                        key: "revenue_target",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: " IA Projected",
                                        key: "revenue_ia",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: " Incremental (IA Projected)",
                                        key: "revenue_incremental_ia",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: " IA Projected (Actualised)",
                                        key: "revenue_finalized",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                ],
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                hidden: false,
                                header: "Margin",
                                columns: [
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Baseline",
                                        key: "gross_margin_baseline",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Target",
                                        key: "gross_margin_target",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "IA Projected",
                                        key: "gross_margin_ia",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Incremental (IA Projected)",
                                        key: "gross_margin_incremental_ia",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "IA projected (Actualised)",
                                        key: "gross_margin_finalized",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                ],
                            },
                        ],
                        add_metrics: {},
                    },
                    marketing_calendar_promo_table: {
                        table_header: [
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Offer Name",
                                key: "promo_name",
                                action: null, //"lockUnlock",
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: true,
                                lockPinned: true,
                                checkbox_selection: true,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: " Start Date",
                                key: "start_date",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "End Date",
                                key: "end_date",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Offer Status",
                                key: "lifecycle",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "Performance",
                                key: "performance",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: false,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "#Products",
                                key: "items",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: true,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                header: "#Stores",
                                key: "stores",
                                action: null,
                                hidden: false,
                                aggregate: null,
                                sorting: true,
                                filter: false,
                                pinned: false,
                                row_group: false,
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                hidden: false,
                                header: "Sales Units",
                                columns: [
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Baseline",
                                        key: "units_baseline",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Target",
                                        key: "units_target",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Incremental (IA Projected)",
                                        key: "units_incremental_ia",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Incremental (Finalized)",
                                        key: "units_incremental_finalized",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "IA Projected",
                                        key: "units_ia",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Actualised",
                                        key: "units_finalized",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                ],
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                hidden: false,
                                header: "Revenue",
                                columns: [
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Baseline",
                                        key: "revenue_baseline",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Target",
                                        key: "revenue_target",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Incremental (IA Projected)",
                                        key: "revenue_incremental_ia",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Incremental (Finalized)",
                                        key: "revenue_incremental_finalized",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "IA Projected",
                                        key: "revenue_ia",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Actualised",
                                        key: "revenue_finalized",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                ],
                            },
                            {
                                type: "static_column",
                                mapping: "data",
                                hidden: false,
                                header: "Margin",
                                columns: [
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Baseline",
                                        key: "gross_margin_baseline",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Target",
                                        key: "gross_margin_target",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Incremental (IA Projected)",
                                        key: "gross_margin_incremental_ia",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Incremental (Finalized)",
                                        key: "gross_margin_incremental_finalized",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "IA Projected",
                                        key: "gross_margin_ia",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                    {
                                        type: "static_column",
                                        mapping: "data",
                                        header: "Actualised",
                                        key: "gross_margin_finalized",
                                        action: null,
                                        hidden: false,
                                        aggregate: null,
                                        sorting: true,
                                        filter: false,
                                        pinned: false,
                                        row_group: false,
                                    },
                                ],
                            },
                        ],
                        add_metrics: {},
                    },
                },
            },
            CREATE_EVENT_APPLICABILITY: {
                filter_options: [
                    {
                        type: "product_h1",
                        is_mandatory: true,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_type: "product_hierarchy",
                    },
                    {
                        type: "product_h2",
                        is_mandatory: true,
                        is_multiple_selection: true,
                        filter_endpoint: "/filters",
                        filter_type: "product_hierarchy",
                    },
                ],
            },
        },
        UI_CONFIG_COMMON_IN_ALL: {
            nav_config: [
                {
                    key: "workbench",
                    title: "Workbench",
                    link: "/workbench",
                },
                {
                    key: "price_rules",
                    title: "Price Rules",
                    link: "/price-rules",
                },
                {
                    key: "configuration_alert",
                    title: "Configuration & Alert",
                    children: [
                        {
                            key: "plan_group_config",
                            title: "Plan Group",
                            link: "/plan-group-config",
                        },
                        {
                            key: "price_list_config",
                            title: "Price List",
                            link: "/price-list-configuration",
                        },
                        {
                            key: "market_config",
                            title: "Market",
                            link: "/market-config",
                        },
                        {
                            key: "alert_config",
                            title: "Alert",
                            link: "/alert-config",
                        },
                        {
                            key: "exception_config",
                            title: "Exception",
                            link: "/exception-config",
                        },
                        {
                            key: "discount_config",
                            title: "Discount",
                            link: "/discount-config",
                        },
                    ],
                },
                {
                    key: "audit_log_download",
                    title: "Audit Log Download",
                    link: "/audit-log-download",
                },
                {
                    key: "approval_log",
                    title: "Approval Log",
                    link: "/approval-log",
                },
                {
                    key: "Planning Roll Up",
                    title: "Planning Roll Up",
                    link: "/planning-roll-up",
                },
            ],
            date_viewing_style: {
                type: "date_range",
                key_names: ["start_of_week_date", "end_of_week_date"],
                available_key_options: [
                    "week_num",
                    "start_of_week_date",
                    "end_of_week_date",
                ],
                avilable_types: ["date_range", "week_num"],
                data_loading: ["create-markdown"],
            },
            element_labels: {
                brand: "Brand",
                product_h1: "Merch Division",
                product_h2: "Merch Org",
                product_h3: "Gender",
                product_h4: "Brand Group",
                product_h5: "Brand",
                product_h6: "Merch Segment",
                product_h7: "Merch Size Group",
                product_h8: "Merch Category",
                product_h9: "Product Category",
                product_h10: "Product Class",
                product_h11: "Product Subclass",
                product_h12: "Plan Level 1",
                product_h13: "Plan Level 2",
                product_h14: "Plan Level 3",
                product_h15: "Plan Level 4",
                product_h16: "Plan Level 5",
                style_number: "Style Number",
                board_cd: "Board Number",
                season_code: "Season Code",
                season_desc: "Season Name",
                sap_material_status: "SAP Status",
                ip_price_export_status: "IP Price Export Status",
                gfe_material_type_ind: "GFE Pre-pack",
                legacy_material_nbr: "Legacy Material Number",
                gfe_material_status: "GFE Material Status",
                vendor_material_nbr: "Vendor Material Number",
                item_number: "Item Number",
                store_h1: "Channel",
                store_h2: "Region",
                store_h3: "State",
                store_h4: "City",
                store_h5: "Store",
                dateRange: "Date Range",
                store_launch_date: "Store Launch Date",
                ecomm_launch_date: "Ecomm Launch Date",
                color: "Color",
                tier: "Store Tier",
                date_range: "Date Range",
                country: "Country",
                store_type: "Store Type",
                international_store: "International Store",
                store_status: "Active Stores",
                store_tier: "Store Tier",
                store_id: "Store ID",
                store_code: "Store ID",
                store_name: "Store Name",
                region: "Region",
                channel: "Channel",
                state: "State",
                product_code: "Product ID",
                product_name: "Product Name",
                department: "Department",
                product_class: "Class",
                product_subclass: "Sub-Class",
                item_group: "Item Group",
                bin: "Tier",
                events: "Events",
                overall: "Overall",
                gross_margin_target: "Target GM",
                target_gross_margin: "Target GM$",
                finalized_margin: "Finalized GM",
                finalized_gross_margin: "Finalized GM",
                finalized_baseline_margin: "Baseline GM",
                finalized_baseline_gross_margin: "Baseline GM",
                finalized_incremental_margin: "Incremental GM",
                finalized_incremental_gross_margin: "Incremental GM",
                projected_margin: "IA Projected GM",
                projected_gross_margin: "IA Projected GM",
                revenue_target: "Target Revenue",
                target_revenue: "Target Revenue",
                finalized_revenue: "Finalized Revenue",
                finalized_baseline_revenue: "Baseline Revenue",
                finalized_incremental_revenue: "Incremental Revenue",
                projected_revenue: "IA Projected Revenue",
                sales_units_target: "Target Sales Units",
                target_sales_units: "Target Sales Units",
                finalized_sales_units: "Finalized Sales Units",
                finalized_baseline_sales_units: "Baseline Sales Units",
                finalized_incremental_sales_units: "Incremental Sales Units",
                projected_sales_units: "IA Projected Sales Units",
                scenario_1: "Scenario 1",
                scenario_2: "Scenario 2",
                affinity_margin: "Margin Affinity",
                affinity_sales: "Sales Affinity",
                aum: "AUM $",
                aur: "AUR $",
                baseline_margin: "Baseline Margin",
                baseline_sales: "Baseline Revenue",
                baseline_sales_units: "Baseline Sales Units",
                cannibalization_margin: "Cannibalization",
                cannibalization_sales: "Cannibalization",
                incremental_margin: "Incremental Margin",
                incremental_sales: "Incremental Revenue",
                incremental_sales_units: "Incremental Sales Units",
                gross_margin: "GM $",
                margin: "GM $",
                baseline: "Baseline",
                sales: "Sales",
                incremental: "Incremental",
                pull_forward_margin: "Pull Forward",
                pull_forward_sales: "Pull Forward",
                revenue: "Revenue",
                sales_units: "Sales Units",
                gm_percent: "GM %",
                affinity: "Affinity",
                cannibalization: "Cannibalization",
                pull_forward: "Pull Forward",
                total: "Total",
                bxgy: "BxGy",
                percent_off: "% Off",
                extra_amount_off: "$ Off",
                fixed_price: "PP",
                projected: "IA Projected",
                target: "Target",
                finalized: "Finalized",
                actual: "Actual",
                lift: "Lift",
                EVENTS: "Event",
                net_incremental: "Lift",
                promo_spend: "Markdown Spend ($)",
            },
            landing_screen: "DECISION_DASHBOARD",
            productConfigurationLevel: {
                name: "Product",
            },
            metrics_ui: ["margin", "sales", "sales_units"],
        },
    },
};
