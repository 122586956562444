import { createSlice } from "@reduxjs/toolkit";

import { API } from "../../../utils/axios/index";
import {
    requestComplete,
    requestFail,
    requestStart,
    toastError,
    toastMessage,
} from "../global/global";

const initialState = {
    marketValidating: false,
    marketValidity: false,
    marketSaving: false,
    markets: [],
    channels: [],
    editMarket: {},
};

const marketSlice = createSlice({
    name: "market",
    initialState,
    reducers: {
        setMarketValidating(state, action) {
            state.marketValidating = action.payload;
        },
        setMarketValidity(state, action) {
            state.marketValidity = action.payload;
        },
        setMarketSaving(state, action) {
            state.marketSaving = action.payload;
        },
        setMarkets(state, action) {
            state.markets = action.payload;
        },
        setChannels(state, action) {
            state.channels = action.payload;
        },
        setEditMarket(state, action) {
            state.editMarket = action.payload;
        },
    },
});

export const {
    setMarketValidating,
    setMarketValidity,
    setMarketSaving,
    setMarkets,
    setChannels,
    setEditMarket,
} = marketSlice.actions;

export default marketSlice.reducer;

export const getMarketNames = () => {
    return API.get("/markets/market-names").then((response) => {
        const { data: marketNames } = response.data;

        return marketNames;
    });
};

export const getMarketDetails = (marketName) => {
    const body = {
        market_name: marketName,
    };

    return API.post("/markets/market-details", body).then((response) => {
        const { data: marketDetails } = response.data;

        return marketDetails;
    });
};

export const fetchMarkets = () => (dispatch) => {
    dispatch(requestStart());

    API.get("/markets")
        .then((response) => {
            const { data: markets } = response.data;

            markets.sort((market1, market2) => {
                if (market1.region.label !== market2.region.label) {
                    return market1.region.label.toLowerCase() <
                        market2.region.label.toLowerCase()
                        ? -1
                        : 1;
                }

                if (market1.channel.label !== market2.channel.label) {
                    return market1.channel.label.toLowerCase() <
                        market2.channel.label.toLowerCase()
                        ? -1
                        : 1;
                }

                if (market1.market_name !== market2.market_name) {
                    return market1.market_name.toLowerCase() <
                        market2.market_name.toLowerCase()
                        ? -1
                        : 1;
                }

                if (market1.season_name !== market2.season_name) {
                    return market1.season_name.toLowerCase() <
                        market2.season_name.toLowerCase()
                        ? -1
                        : 1;
                }

                return market1.product_h1_name.toLowerCase() <
                    market2.product_h1_name.toLowerCase()
                    ? -1
                    : 1;
            });

            dispatch(setMarkets(markets));

            dispatch(requestComplete());
        })
        .catch((err) => {
            const message =
                err?.response?.data?.message ?? "Failed to load markets";

            dispatch(requestFail(message));
        });
};

let editMarketHandle;

export const editMarkets = (data) => (dispatch) => {
    dispatch(requestStart());

    return API.post("/edit_market", data)
        .then((response) => {
            const { data: resData, message } = response.data;

            dispatch(fetchMarkets());

            if (resData) {
                dispatch(
                    requestComplete({ success: true, successMessage: message })
                );

                clearTimeout(editMarketHandle);

                dispatch(setEditMarket(data));

                editMarketHandle = setTimeout(() => {
                    dispatch(setEditMarket({}));
                }, 2000);
            } else {
                dispatch(requestFail(message));
            }
        })
        .catch((err) => {
            const message =
                err?.response?.data?.message ?? "Market edit failed";

            dispatch(requestFail(message));
        });
};

export const validateMarkets = (data) => (dispatch) => {
    dispatch(setMarketValidating(true));
    dispatch(requestStart());

    return API.post("/markets/add-new-markets", data)
        .then((response) => {
            const { data, message } = response.data;

            dispatch(setMarketValidity(data));

            // if (!data) {
            //     dispatch(toastError(message));
            // } else {
            //     dispatch(
            //         toastMessage(
            //             "File uploaded successfully. Click 'Submit' to finalise."
            //         )
            //     );
            // }

            if (!data) {
                throw message;
            }
        })
        .finally(() => {
            dispatch(requestComplete());
            dispatch(setMarketValidating(false));
        });
};

export const uploadMarkets = (data) => (dispatch) => {
    dispatch(setMarketSaving(true));
    dispatch(requestStart());

    return API.post("/markets/save-markets", data)
        .then(() => {
            dispatch(
                requestComplete({
                    success: true,
                    successMessage: "Markets saved successfully.",
                })
            );

            dispatch(fetchMarkets());
            dispatch(setMarketValidity(false));
        })
        .catch(() => {
            dispatch(requestFail("Market upload failed"));
        })
        .finally(() => {
            dispatch(setMarketSaving(false));
        });
};

export const deleteMarket = (payload) => (dispatch) => {
    dispatch(requestStart());
    return API.delete("markets/delete-market-details", { data: payload })
        .then((res) => {
            dispatch(requestComplete());
            return res;
        })
        .catch((err) => {
            dispatch(
                requestFail(err?.response?.data?.message || err?.message || err)
            );
            throw new Error("Empty error");
        });
};

export const editMarketGroup = (payload) => (dispatch) => {
    dispatch(requestStart());

    return API.post("/edit-market-group", payload)
        .then(() => {
            dispatch(requestComplete("Market group updated successfully"));

            dispatch(fetchMarkets());
        })
        .catch((err) => {
            const message =
                err?.response?.data?.message ?? "Market group update failed";

            dispatch(requestFail(message));
        });
};
