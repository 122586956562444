import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { TextField, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Tab from "@mui/material/Tab";
import MultiSelect from "../../common/filters/Select/Select";
import { cloneDeep, isEqual } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { toastError } from "../../../store/features/global/global";
import {
    addColumnSetting,
    deleteColumnSetting,
    editColumnSetting,
    makeColumnSettingDefault,
    setSelectedViewIndex,
    setUpdatedHierarchyOptions,
    userUpdateColumnSettings,
} from "../../../store/features/workbench/workbench";
import { getColPinValue } from "./EUPriceListLevelPrices";
import { TabPanel } from "./EUPricePlanningFilterAttributesMaterials-New";

import LeftPin from "../../../assets/images/pins/left-pin.svg";
import NoPin from "../../../assets/images/pins/no-pin.svg";
import RightPin from "../../../assets/images/pins/right-pin.svg";
import { outletNameReplacementMapping } from "./EUCountryLevelPrices";
// import { filterAndMapColumnChannelWise } from "./euHelper";

import tableStyles from "./TableSettings.module.scss";
import { filterAndMapColumnChannelWise } from "./euHelper";

export const tableSettingColumnWidthModes = {
    AUTO: "0",
    CUSTOM: "1",
};

export const tableSettingTabIds = {
    BASE_ATTRIBUTE_SETTINGS: "0",
    REGIONAL_ATTRIBUTE_SETTINGS: "1",
    PRICE_LIST_SETTINGS: "2",
    BUYING_ROLL_UPS: "3",
    PLANNING_ROLL_UPS: "4",
};

export const columnViewSaveModes = {
    SAVE_DEFAULT_AS: "0",
    SAVE_VIEW_AS: "1",
    RENAME: "2",
};

function TableSettingName(props) {
    const { view, active, index, onClick, onEdit } = props;

    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const onSelectClick = () => {
        onClick(index);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onSaveAsDefaultClick = () => {
        if (view.view_id === -1) {
            return;
        }

        dispatch(makeColumnSettingDefault(view.view_id));

        setAnchorEl(null);
    };

    const onEditClick = () => {
        if (view.view_id === -1) {
            return;
        }

        onEdit();

        setAnchorEl(null);
    };

    const onDeleteClick = () => {
        if (view.view_id === -1) {
            return;
        }

        dispatch(deleteColumnSetting(view.view_id));

        setAnchorEl(null);
    };

    return (
        <button
            className={`${tableStyles["name-container"]} ${
                active ? tableStyles["active-name"] : ""
            } align-center pointer`}
            onClick={onSelectClick}
        >
            <Tooltip title={view.view_data.title} arrow>
                <span
                    className="overflow-hidden no-wrap text-ellipsis"
                    style={{
                        marginRight: view.view_id !== -1 ? 12 : 0,
                        maxWidth: 100,
                    }}
                >
                    {view.view_data.title}
                </span>
            </Tooltip>
            {view.view_id !== -1 ? (
                <MoreVertIcon sx={{ fontSize: 16 }} onClick={handleClick} />
            ) : null}
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    style: {
                        padding: 0,
                    },
                }}
            >
                <MenuItem onClick={onSaveAsDefaultClick}>
                    Save As Default
                </MenuItem>
                <MenuItem onClick={onEditClick}>Edit View</MenuItem>
                <MenuItem onClick={onDeleteClick}>Delete View</MenuItem>
            </Menu>
        </button>
    );
}

function ColPin(props) {
    const { pinned, onChange } = props;

    const [pinStatus, setPinStatus] = useState(null);

    useEffect(() => {
        setPinStatus(getColPinValue(pinned));
    }, [pinned]);

    const onPinStatusClick = () => {
        onChange(pinStatus === "right" ? null : pinStatus ? "right" : "left");
    };

    return (
        <button
            className={`${tableStyles["pin-container"]} align-center`}
            onClick={onPinStatusClick}
            onKeyDown={() => {}}
        >
            {pinStatus === "right" ? (
                <img src={RightPin} alt="right-pin" />
            ) : pinStatus ? (
                <img src={LeftPin} alt="left-pin" />
            ) : (
                <img src={NoPin} alt="no-pin" />
            )}
        </button>
    );
}

function ColumnItem(props) {
    const {
        colSearchText,
        index,
        column: { isSelected, pinned, name },
        onDragStart,
        onDragOver,
        onDrop,
        onColSelectionChange,
        onColPinChange,
    } = props;

    const onColSelectionCheckboxChange = (event) => {
        onColSelectionChange(index, event.target.checked);
    };

    const onColPinCheckboxChange = (newStatus) => {
        onColPinChange(index, newStatus);
    };

    return (
        <button
            className="align-center"
            draggable={!colSearchText.trim().length}
            data-index={index}
            onDragStart={onDragStart}
            onDragOver={onDragOver}
            onDrop={onDrop}
            style={{ border: "none", background: "white" }}
        >
            <Checkbox
                size="small"
                onChange={onColSelectionCheckboxChange}
                checked={isSelected}
            />
            <DragIndicatorIcon
                style={{
                    color: colSearchText.trim().length ? "#ddd" : "#707070",
                    fontSize: 16,
                    marginLeft: 4,
                    marginRight: 12,
                    cursor: colSearchText.trim().length ? "no-drop" : "pointer",
                }}
            />
            <ColPin pinned={pinned} onChange={onColPinCheckboxChange} />
            <span className={`${tableStyles["column-name"]}`}>{name}</span>
        </button>
    );
}

const SECTION_TYPE = {
    HIERARCHY: "hierarchy",
    METRICS: "metrics",
};

const DRAGGABLE_SECTION_TYPE = {
    HIERARHY_SECTION: {
        SELECTED_SECTION: `${SECTION_TYPE.HIERARCHY}-selected-section`,
        REST_SECTION: `${SECTION_TYPE.HIERARCHY}-rest-section`,
        PARENT_SELECTED_SECTION: `parent-hierarchy-selected-section`,
        PARENT_REST_SECTION: `parent-hierarchy-rest-section`,
    },
    METRICS_SECTION: {
        SELECTED_SECTION: `${SECTION_TYPE.METRICS}-selected-section`,
        REST_SECTION: `${SECTION_TYPE.METRICS}-rest-section`,
        PARENT_SELECTED_SECTION: `parent-metrics-selected-section`,
        PARENT_REST_SECTION: `parent-metrics-rest-section`,
    },
};

function RollUpSettingsComponent(props) {
    const {
        views,
        setViews,
        selectedViewIndex,
        columns,
        buyingRollUpsSettings,
        planningRollUpSettings,
        channel,
    } = props;

    const [draggedIndex, setDraggedIndex] = useState({});
    const [seasonList, setSeasonList] = useState([]);
    const [yearsList, setYearsList] = useState([]);
    const [projectedOrActualList, setProjectedOrActualList] = useState([]);
    const [subchannelList, setSubchannelList] = useState([]);

    const [isMetreicsVersionThisYear, setIsMetreicsVersionThisYear] =
        useState(false);
    const dispatch = useDispatch();
    const hierarchyOptions =
        views[selectedViewIndex].view_data.hierarchyOptions;

    useEffect(() => {
        const columns = cloneDeep(views[selectedViewIndex].view_data.columns);
        if (buyingRollUpsSettings) {
            setSeasonList(columns);
            setYearsList(columns[0]?.children || []);
            setProjectedOrActualList(
                columns[0]?.children[0]?.children[0]?.children || []
            );
            let tempSubChannelLIst =
                columns[0]?.children[0]?.children[0]?.children[0]?.children ||
                [];
            if (channel === "OUTLET") {
                tempSubChannelLIst = tempSubChannelLIst[0];
                tempSubChannelLIst.isSelected = true;
                tempSubChannelLIst = [tempSubChannelLIst];
            }
            setSubchannelList(tempSubChannelLIst);
        } else {
            setSeasonList(columns[1]?.children || []);
            setYearsList(columns[1]?.children[0]?.children || []);
            setProjectedOrActualList(
                columns[1]?.children[0]?.children[0]?.children[0]?.children ||
                    []
            );

            let tempSubChannelLIst =
                columns[1]?.children[0]?.children[0]?.children[0]?.children[0]
                    ?.children || [];
            if (channel === "OUTLET") {
                tempSubChannelLIst = tempSubChannelLIst[0];
                tempSubChannelLIst.isSelected = true;
                tempSubChannelLIst = [tempSubChannelLIst];
            }
            setSubchannelList(tempSubChannelLIst);
        }

        if (buyingRollUpsSettings) {
            if (
                views[selectedViewIndex].view_data?.selectedMetricVersion
                    ?.length > 0
            ) {
                const tempIsMetreicsVersionThisYear =
                    views[selectedViewIndex].view_data?.selectedMetricVersion[0]
                        .value === "ty";
                if (
                    tempIsMetreicsVersionThisYear === isMetreicsVersionThisYear
                ) {
                    return;
                }

                setIsMetreicsVersionThisYear(tempIsMetreicsVersionThisYear);
            }
        }
    }, [
        columns,
        selectedViewIndex,
        views,
        buyingRollUpsSettings,
        planningRollUpSettings,
        isMetreicsVersionThisYear,
    ]);

    const setYearListForColDef = (year) => {
        const selectedProjectedOrActualList = projectedOrActualList.filter(
            (item) => item.isSelected
        );
        const selectedSubchannelList = subchannelList.filter(
            (item) => item.isSelected
        );

        year.children.forEach((metrics) => {
            setSelectedItemInList(
                metrics.children,
                selectedProjectedOrActualList
            );
            metrics.children.forEach((dataType) => {
                setSelectedItemInList(
                    dataType.children,
                    selectedSubchannelList
                );
            });
        });
    };

    const updateView = () => {
        const newViews = cloneDeep(views);
        const columns = newViews[selectedViewIndex].view_data.columns;

        const updateColumns = (columnList, seasonFilter) => {
            setSelectedItemInList(
                columnList,
                seasonFilter.filter((item) => item.isSelected)
            );

            columnList.forEach((column) => {
                setSelectedItemInList(
                    column.children,
                    yearsList.filter((item) => item.isSelected)
                );
                column.children.forEach((year) => {
                    setYearListForColDef(year);
                });
            });
        };

        if (buyingRollUpsSettings) {
            updateColumns(columns, seasonList);
        } else {
            columns.forEach((fullYearSeason, index) => {
                if (index === 0) {
                    const totalSeason = seasonList[0];
                    if (totalSeason) {
                        fullYearSeason.isSelected = totalSeason.isSelected;
                    }
                    updateColumns(fullYearSeason.children, yearsList);
                } else {
                    updateColumns(fullYearSeason.children, seasonList);
                }
            });
        }

        setViews(newViews);
    };

    const setSelectedItemInList = (list, selectedList, retainOrder) => {
        const tempSelectedList = selectedList.map((item, index) => ({
            ...item,
            order: index,
        }));

        if (list?.length > 0) {
            list.forEach((item) => {
                const selectedItem = tempSelectedList.find(
                    (selectedItem) => selectedItem.label === item.label
                );

                item.isSelected = !!selectedItem;
                if (item.isSelected) {
                    item.order = selectedItem.order;
                } else {
                    item.order = tempSelectedList.length;
                }
            });

            if (retainOrder) list.sort((a, b) => a.order - b.order);

            list.forEach((item) => {
                delete item.order;
            });
        } else {
            console.error("Empty array for update", { list, tempSelectedList });
        }
    };

    const changeMetrics = (updatedList) => {
        const newViews = cloneDeep(views);

        const filterCondition = (updatedItem) => updatedItem.isSelected;
        const updateSelectedItems = (columnList) => {
            columnList.children.forEach((item) => {
                setSelectedItemInList(
                    item.children,
                    updatedList.filter(filterCondition),
                    true
                );
            });
        };

        if (buyingRollUpsSettings) {
            newViews[selectedViewIndex].view_data.columns.forEach(
                updateSelectedItems
            );
        } else {
            newViews[selectedViewIndex].view_data.columns.forEach(
                (markets, index) => {
                    if (index === 0) {
                        updateSelectedItems(markets);
                    } else {
                        markets.children.forEach(updateSelectedItems);
                    }
                }
            );
        }

        setViews(newViews);
    };

    const getListAsPerSection = (sectionType) => {
        let list = [];
        const columns = cloneDeep(views[selectedViewIndex].view_data.columns);

        if (
            DRAGGABLE_SECTION_TYPE.HIERARHY_SECTION.REST_SECTION.includes(
                sectionType
            )
        ) {
            list = hierarchyOptions.filter((item) => !item.isSelected);
        }

        if (
            DRAGGABLE_SECTION_TYPE.HIERARHY_SECTION.SELECTED_SECTION.includes(
                sectionType
            )
        ) {
            list = hierarchyOptions.filter((item) => item.isSelected);
        }

        if (
            DRAGGABLE_SECTION_TYPE.METRICS_SECTION.REST_SECTION.includes(
                sectionType
            )
        ) {
            list = columns[0]?.children[0]?.children?.filter(
                (item) => !item.isSelected
            );
        }

        if (
            DRAGGABLE_SECTION_TYPE.METRICS_SECTION.SELECTED_SECTION.includes(
                sectionType
            )
        ) {
            list = columns[0]?.children[0]?.children?.filter(
                (item) => item.isSelected
            );
        }
        return cloneDeep(list);
    };

    const onDragStart = ({ event, elementDropType: from, index, isParent }) => {
        if (!isParent) {
            setDraggedIndex({
                index,
                from,
            });
        }
    };

    const preventDefaultEvents = (event) => {
        event.preventDefault();
    };

    const getRestList = (dropType) =>
        cloneDeep(
            getListAsPerSection(
                DRAGGABLE_SECTION_TYPE[
                    dropType.includes(SECTION_TYPE.HIERARCHY)
                        ? "HIERARHY_SECTION"
                        : "METRICS_SECTION"
                ][
                    dropType.includes("selected")
                        ? "REST_SECTION"
                        : "SELECTED_SECTION"
                ]
            )
        );

    const dropLists = (dropType, isParent, finalIndex, droppingList) => {
        let removedEles = null;
        let selectedList;
        let nonSelectedList;

        if (dropType.includes(draggedIndex.from)) {
            if (isParent) finalIndex = droppingList.length - 1;
            removedEles = droppingList.splice(draggedIndex.index, 1);
            droppingList.splice(finalIndex, 0, ...removedEles);
            const restList = getRestList(dropType);

            if (dropType.includes("selected")) {
                nonSelectedList = restList;
                selectedList = droppingList;
            } else {
                selectedList = restList;
                nonSelectedList = droppingList;
            }
        } else {
            const restList = cloneDeep(getListAsPerSection(draggedIndex.from));
            if (dropType.includes("selected")) {
                nonSelectedList = restList;
                selectedList = droppingList;
            } else {
                selectedList = restList;
                nonSelectedList = droppingList;
            }

            if (
                dropType ===
                    DRAGGABLE_SECTION_TYPE.HIERARHY_SECTION.SELECTED_SECTION ||
                dropType ===
                    DRAGGABLE_SECTION_TYPE.METRICS_SECTION.SELECTED_SECTION
            ) {
                nonSelectedList[draggedIndex.index].isSelected = true;
            } else {
                selectedList[draggedIndex.index].isSelected = false;
            }
        }

        return [selectedList, nonSelectedList];
    };

    const onDrop = ({
        event,
        elementDropType: dropType,
        finalIndex,
        isParent,
        droppingList,
    }) => {
        event.stopPropagation();
        if (!isDropable(dropType)) {
            return;
        }

        const [selectedList, nonSelectedList] = dropLists(
            dropType,
            isParent,
            finalIndex,
            droppingList
        );

        if (dropType.includes(SECTION_TYPE.HIERARCHY)) {
            dispatch(
                setUpdatedHierarchyOptions({
                    tabId: buyingRollUpsSettings
                        ? tableSettingTabIds.BUYING_ROLL_UPS
                        : tableSettingTabIds.PLANNING_ROLL_UPS,
                    viewIndex: selectedViewIndex,
                    newData: [...selectedList, ...nonSelectedList],
                })
            );
        }

        if (dropType.includes(SECTION_TYPE.METRICS)) {
            changeMetrics([...selectedList, ...nonSelectedList]);
        }

        setDraggedIndex({});
    };

    const isDropable = (elementDropType) => {
        return (
            (draggedIndex?.from?.includes(SECTION_TYPE.HIERARCHY) &&
                elementDropType.includes(SECTION_TYPE.HIERARCHY)) ||
            (draggedIndex?.from?.includes(SECTION_TYPE.METRICS) &&
                elementDropType.includes(SECTION_TYPE.METRICS))
        );
    };

    const draggableSection = ({ subTitle, sectionType }) => {
        const elementDropType = sectionType;

        let list = getListAsPerSection(sectionType).map((item, index) => ({
            ...item,
            finalIndex: index,
        }));

        if (
            ["Available Hierarchy Levels", "Available Metrics "].includes(
                subTitle
            )
        ) {
            list.sort((a, b) => {
                const textA = a.label.toUpperCase();
                const textB = b.label.toUpperCase();
                if (textA < textB) return -1;
                return textA > textB ? 1 : 0;
            });
        }

        const fromClass = isDropable(elementDropType)
            ? "dragabble-section-active"
            : "dragabble-section-blocked";

        return (
            <div
                className={`${
                    tableStyles["half-width"]
                } ${subTitle.toLowerCase()} ${
                    draggedIndex?.from ? fromClass : ""
                }`}
            >
                <div
                    className={`${tableStyles["buying-roll-up-sub-title"]} pivot-subtitle`}
                >
                    {subTitle}
                </div>
                <button
                    className={`${tableStyles["draggable-section"]} solid-border`}
                    onDragOver={preventDefaultEvents}
                    onDrop={(event) =>
                        onDrop({
                            event,
                            finalIndex: -1,
                            elementDropType,
                            isParent: true,
                            droppingList: list,
                        })
                    }
                >
                    {list?.map((item) => (
                        <button
                            className={`${tableStyles["chip"]} chip_drag align-center gap-10 pointer`}
                            style={{ color: "black" }}
                            draggable
                            onDragStart={(event) =>
                                onDragStart({
                                    event,
                                    elementDropType,
                                    index: item.finalIndex,
                                })
                            }
                            onDragOver={preventDefaultEvents}
                            onDrop={(event) =>
                                onDrop({
                                    event,
                                    finalIndex: item.finalIndex,
                                    elementDropType,
                                    isParent: false,
                                    droppingList: list,
                                })
                            }
                            key={item.label}
                        >
                            <Tooltip arrow>
                                <button
                                    onDrop={preventDefaultEvents}
                                    style={{
                                        background: "transparent",
                                        border: "none",
                                        padding: 0,
                                        cursor: "pointer",
                                    }}
                                >
                                    {item.label}
                                </button>
                            </Tooltip>
                        </button>
                    ))}
                </button>
            </div>
        );
    };

    const dropDownSection = ({ label, list, setList, isDisabled = false }) => {
        const updateSelected = (data) => {
            setSelectedItemInList(list, data.selectedItems);
            setList([...list]);
            setTimeout(() => updateView(), 500);
        };

        const getSelectedList = () => {
            if (label === "Years" && isDisabled) {
                return [columns[0]?.children[0]];
            }

            return list.filter((item) => item.isSelected) || [];
        };

        return (
            <div className={`${tableStyles["half-width"]}`}>
                <div className={`${tableStyles["buying-roll-up-sub-title"]}`}>
                    {label}
                </div>
                {channel === "OUTLET" && label === "Subchannel" ? (
                    <TextField
                        disabled
                        value={"Outlet"}
                        style={{
                            height: 35,
                            background: "#eee",
                            color: "#1d1d1d",
                        }}
                    />
                ) : (
                    <MultiSelect
                        className="input"
                        initialData={list}
                        isDisabled={isDisabled}
                        selectedOptions={getSelectedList()}
                        updateSelected={updateSelected}
                        is_multiple_selection
                        updateSelectedOnEachSelection
                    />
                )}
            </div>
        );
    };

    return (
        <div className={`${tableStyles["buying-roll-up"]}`}>
            <div className="top_filter_main_container">
                <h2 className="filter_top_filter_heading">
                    Filter table settings
                </h2>
                {buyingRollUpsSettings && (
                    <div className="center-space-between flex-1 min-height-0 flex-wrap align-top">
                        {dropDownSection({
                            label: "Market",
                            list: seasonList,
                            setList: setSeasonList,
                        })}
                        {dropDownSection({
                            label: "Years",
                            list: yearsList,
                            setList: setYearsList,
                            isDisabled: isMetreicsVersionThisYear,
                        })}
                    </div>
                )}
                <div className="center-space-between flex-1 min-height-0 flex-wrap align-top">
                    {dropDownSection({
                        label: "Projected / Actual",
                        list: projectedOrActualList,
                        setList: setProjectedOrActualList,
                    })}
                    {dropDownSection({
                        label: "Subchannel",
                        list: subchannelList,
                        setList: setSubchannelList,
                    })}
                </div>
            </div>
            <div className="center-space-between flex-1 min-height-0 flex-wrap align-top top_filter_main_container">
                <div style={{ width: "100%" }}>
                    <h2 className="filter_top_filter_heading">
                        Pivot Settings
                    </h2>
                </div>
                {draggableSection({
                    subTitle: "Available Hierarchy Levels",
                    sectionType:
                        DRAGGABLE_SECTION_TYPE.HIERARHY_SECTION.REST_SECTION,
                })}
                {draggableSection({
                    subTitle: "Selected Hierarchy Levels",
                    sectionType:
                        DRAGGABLE_SECTION_TYPE.HIERARHY_SECTION
                            .SELECTED_SECTION,
                })}
                {draggableSection({
                    subTitle: "Available Metrics",
                    sectionType:
                        DRAGGABLE_SECTION_TYPE.METRICS_SECTION.REST_SECTION,
                })}
                {draggableSection({
                    subTitle: "Selected Metrics",
                    sectionType:
                        DRAGGABLE_SECTION_TYPE.METRICS_SECTION.SELECTED_SECTION,
                })}
            </div>
        </div>
    );
}

RollUpSettingsComponent.propTypes = {
    planningRollUpSettings: PropTypes.bool,
    views: PropTypes.array,
    setViews: PropTypes.func,
    columns: PropTypes.array,
    buyingRollUpsSettings: PropTypes.bool,
    selectedViewIndex: PropTypes.number,
    channel: PropTypes.string,
};

function ViewSettings(props) {
    const {
        priceListSettings,
        buyingRollUpsSettings,
        planningRollUpSettings,
        views,
        setViews,
        selectedViewIndex,
        setSelectedViewIndex,
        onEdit,
        selectedChannels,
    } = props;

    const {
        general: { selectedTab },
        filterByAttributes: { countryLevelPrices: attributesRegionalPrices },
        filterByPlangroup: { countryLevelPrices: plangroupsRegionalPrices },
    } = useSelector((state) => state.workbench);

    const [selectAll, setSelectAll] = useState(false);
    const [colSearchText, setColSearchText] = useState("");
    const [columns, setColumns] = useState([]);
    const [draggedIndex, setDraggedIndex] = useState(null);

    useEffect(() => {
        setColSearchText("");
    }, [selectedViewIndex]);

    useEffect(() => {
        const colSearchTextLowerCase = colSearchText.trim().toLowerCase();
        let filteredColumns = [];
        const isOutlet = selectedChannels?.[0]?.label === "OUTLET";

        if (priceListSettings) {
            const regionalPrices =
                selectedTab === 2
                    ? attributesRegionalPrices
                    : plangroupsRegionalPrices;
            const priceListIds = [];

            for (const regionalPrice of regionalPrices) {
                for (const priceListName in regionalPrice.country_data) {
                    const priceListId =
                        regionalPrice.country_data[priceListName].price_list_id;

                    if (!priceListIds.includes(priceListId)) {
                        priceListIds.push(priceListId);
                    }
                }
            }

            filteredColumns =
                views[selectedViewIndex]?.view_data.columns.filter(
                    ({ id, name }) =>
                        (selectedViewIndex === 0 &&
                            name
                                .toLowerCase()
                                .includes(colSearchTextLowerCase)) ||
                        (priceListIds.includes(id) &&
                            name.toLowerCase().includes(colSearchTextLowerCase))
                ) ?? [];
        } else if (buyingRollUpsSettings || planningRollUpSettings) {
            filteredColumns = views[selectedViewIndex]?.view_data.columns;
        } else {
            const outletReplacementKeys = Object.keys(
                outletNameReplacementMapping
            );

            filteredColumns =
                views[selectedViewIndex]?.view_data.columns
                    .map((col) =>
                        isOutlet &&
                        outletReplacementKeys.includes(col.name.toLowerCase())
                            ? {
                                  ...col,
                                  name: outletNameReplacementMapping[
                                      col.name.toLowerCase()
                                  ],
                              }
                            : col
                    )
                    .filter(({ name }) =>
                        name.toLowerCase().includes(colSearchTextLowerCase)
                    ) ?? [];
        }

        filteredColumns = filterAndMapColumnChannelWise(
            filteredColumns,
            selectedChannels
        );

        setColumns(filteredColumns);
    }, [
        views,
        selectedViewIndex,
        colSearchText,
        selectedTab,
        attributesRegionalPrices,
        plangroupsRegionalPrices,
        priceListSettings,
        buyingRollUpsSettings,
        planningRollUpSettings,
    ]);

    useEffect(() => {
        let flag = true;

        columns.forEach((column) => {
            if (!column.isSelected) {
                flag = false;
            }
        });
        setSelectAll(flag);
    }, [columns]);

    const onViewClick = (newViewIndex) => {
        setSelectedViewIndex(newViewIndex);
    };

    const onViewEditClick = () => {
        onEdit();
    };

    const onViewDeleteClick = () => {};

    const onColWidthModeChange = (event) => {
        const newViews = cloneDeep(views);

        newViews[selectedViewIndex].view_data.columnWidth = event.target.value;

        setViews(newViews);
    };

    const onSelectAllChange = (event) => {
        const newValue = event.target.checked;
        const newViews = cloneDeep(views);

        newViews[selectedViewIndex].view_data.columns.forEach((column) => {
            const foundIndex = columns.findIndex(
                (filteredCol) => filteredCol.id === column.id
            );

            if (foundIndex !== -1) {
                column.isSelected = newValue;
            }
        });

        setViews(newViews);
    };

    const onColSelectionChange = (index, newValue) => {
        const newViews = cloneDeep(views);
        const editedColumn = columns[index];

        newViews[selectedViewIndex].view_data.columns.forEach((column) => {
            if (column.id === editedColumn.id) {
                column.isSelected = newValue;
            }
        });

        setViews(newViews);
    };

    const onColPinChange = (index, newValue) => {
        const newViews = cloneDeep(views);
        const editedColumn = columns[index];

        newViews[selectedViewIndex].view_data.columns.forEach((column) => {
            if (column.id === editedColumn.id) {
                column.pinned = newValue;
            }
        });

        setViews(newViews);
    };

    const onColSearchTextChange = (event) => {
        setColSearchText(event.target.value);
    };

    const onDragStart = (event) => {
        setDraggedIndex(event.currentTarget.getAttribute("data-index"));
    };

    const onDragOver = (event) => {
        event.preventDefault();
    };

    const onDrop = (event) => {
        const finalIndex = event.currentTarget.getAttribute("data-index");

        const newViews = cloneDeep(views);
        const newColumns = cloneDeep(columns);
        const removedEles = newColumns.splice(draggedIndex, 1);

        newColumns.splice(finalIndex, 0, ...removedEles);
        newViews[selectedViewIndex].view_data.columns = newColumns;

        setViews(newViews);
    };

    return (
        <div
            className={`${tableStyles["view-container"]} flex-column flex-grow-1`}
        >
            <div>
                <div className={`${tableStyles["sub-title"]}`}>Saved Views</div>
                <div
                    className={`align-center flex-wrap gap-12 overflow-auto`}
                    style={{ maxHeight: 72 }}
                >
                    {views
                        ?.filter(
                            (view, index) =>
                                index === 0 ||
                                (!view.view_data.channel &&
                                    selectedChannels?.[0]?.label !==
                                        "OUTLET") ||
                                selectedChannels?.[0]?.label ===
                                    view.view_data.channel
                        )
                        ?.map((view, index) => (
                            <TableSettingName
                                key={view.view_id}
                                active={index === selectedViewIndex}
                                view={view}
                                index={index}
                                onClick={onViewClick}
                                onEdit={onViewEditClick}
                                onDelete={onViewDeleteClick}
                            />
                        ))}
                </div>
            </div>
            <div className={`${tableStyles["divider"]} my-16`}></div>
            {views[selectedViewIndex] ? (
                <div className={`${tableStyles["col-width-mode-container"]}`}>
                    <div className={`${tableStyles["sub-title"]}`}>
                        Column Width
                    </div>
                    <RadioGroup
                        row
                        value={views[selectedViewIndex].view_data.columnWidth}
                        onChange={onColWidthModeChange}
                    >
                        <FormControlLabel
                            value={tableSettingColumnWidthModes.AUTO}
                            control={<Radio size="small" />}
                            label="Auto adjust"
                        />
                        <FormControlLabel
                            value={tableSettingColumnWidthModes.CUSTOM}
                            control={<Radio size="small" />}
                            label="Custom width"
                        />
                    </RadioGroup>
                </div>
            ) : null}
            {!(buyingRollUpsSettings || planningRollUpSettings) && (
                <>
                    <div className={`${tableStyles["divider"]} my-16`}></div>
                    <div className="flex-column flex-1 min-height-0">
                        <div className={`${tableStyles["sub-title"]}`}>
                            Columns
                        </div>
                        <div
                            className="align-center"
                            style={{ marginBottom: 8 }}
                        >
                            <Checkbox
                                size="small"
                                checked={selectAll}
                                onChange={onSelectAllChange}
                            />
                            <OutlinedInput
                                id="outlined-adornment-amount"
                                placeholder="Search"
                                startAdornment={
                                    <SearchIcon
                                        style={{
                                            marginRight: 12,
                                            color: "#75849880",
                                        }}
                                    />
                                }
                                style={{ height: 36 }}
                                value={colSearchText}
                                onChange={onColSearchTextChange}
                            />
                        </div>
                        <div
                            className="flex-column flex-grow-1 overflow-auto"
                            style={{ position: "relative", fontSize: 14 }}
                        >
                            {columns.map((column, index) => (
                                <ColumnItem
                                    colSearchText={colSearchText}
                                    index={index}
                                    column={column}
                                    onDragStart={onDragStart}
                                    onDragOver={onDragOver}
                                    onDrop={onDrop}
                                    onColSelectionChange={onColSelectionChange}
                                    onColPinChange={onColPinChange}
                                />
                            ))}
                        </div>
                    </div>
                </>
            )}
            {(buyingRollUpsSettings || planningRollUpSettings) && (
                <div className={`${tableStyles["divider"]} mt-16`}></div>
            )}
            {(buyingRollUpsSettings || planningRollUpSettings) && (
                <RollUpSettingsComponent
                    {...props}
                    columns={columns}
                    buyingRollUpsSettings={buyingRollUpsSettings}
                    planningRollUpSettings={planningRollUpSettings}
                    setColumns={setColumns}
                    channel={selectedChannels?.[0]?.label}
                />
            )}
        </div>
    );
}

ViewSettings.propTypes = {
    planningRollUpSettings: PropTypes.bool,
    views: PropTypes.array,
    setViews: PropTypes.func,
    selectedViewIndex: PropTypes.number,
    setSelectedViewIndex: PropTypes.func,
    onEdit: PropTypes.func,
    onApplyClick: PropTypes.func,
    buyingRollUpsSettings: PropTypes.bool,
    selectedChannels: PropTypes.arrayOf(
        PropTypes.shape({ label: PropTypes.string })
    ),
};

function TableSettings(props) {
    const {
        basePriceSettings,
        regionalPriceSettings,
        priceListSettings,
        buyingRollUpsSettings,
        planningRollUpSettings,
        onApply,
        onClose,
        selectedChannels,
    } = props;

    const dispatch = useDispatch();

    const [tableSettingTab, setTableSettingTab] = useState(-1);
    const [saveMode, setSaveMode] = useState(null);
    const [viewNewName, setViewNewName] = useState("");
    const [viewNewNameTrimmed, setViewNewNameTrimmed] = useState("");
    const [viewNewNameError, setViewNewNameError] = useState(false);

    const {
        general: {
            columnSettings,
            columnSettingsOriginal,
            userResizedColumns,
            selectedViewIndex,
        },
    } = useSelector((state) => state.workbench);

    useEffect(() => {
        if (basePriceSettings) {
            setTableSettingTab(tableSettingTabIds.BASE_ATTRIBUTE_SETTINGS);
        } else if (regionalPriceSettings) {
            setTableSettingTab(tableSettingTabIds.REGIONAL_ATTRIBUTE_SETTINGS);
        } else if (priceListSettings) {
            setTableSettingTab(tableSettingTabIds.PRICE_LIST_SETTINGS);
        } else if (buyingRollUpsSettings) {
            setTableSettingTab(tableSettingTabIds.BUYING_ROLL_UPS);
        } else if (planningRollUpSettings) {
            setTableSettingTab(tableSettingTabIds.PLANNING_ROLL_UPS);
        }
    }, []);

    useEffect(() => {
        setSaveMode(null);
    }, [tableSettingTab]);

    useEffect(() => {
        setSaveMode(null);
    }, [selectedViewIndex]);

    useEffect(() => {
        if (!saveMode) {
            setViewNewName("");
            setViewNewNameError(false);
        }
    }, [saveMode]);

    useEffect(() => {
        setViewNewNameTrimmed(viewNewName.trim());
    }, [viewNewName]);

    useEffect(() => {
        if (
            tableSettingTab > -1 &&
            selectedViewIndex[tableSettingTab] >=
                columnSettings[tableSettingTab].length
        ) {
            dispatch(
                setSelectedViewIndex({ tabId: tableSettingTab, newData: 0 })
            );
        }
    }, [columnSettings, selectedViewIndex, tableSettingTab]);

    const onCloseClick = () => {
        onClose();
    };

    const onViewChange = (newViews) => {
        dispatch(
            userUpdateColumnSettings({
                tabId: tableSettingTab,
                views: newViews,
            })
        );
    };

    const onSelectedViewChange = (newIndex) => {
        dispatch(
            setSelectedViewIndex({ tabId: tableSettingTab, newData: newIndex })
        );
    };

    const onEdit = () => {
        setViewNewName(
            columnSettings[tableSettingTab][selectedViewIndex[tableSettingTab]]
                .view_data.title
        );

        setSaveMode(columnViewSaveModes.RENAME);
    };

    const onViewNewNameChange = (event) => {
        const newValue = event.target.value;

        if (newValue.length > 255) {
            setViewNewNameError(true);

            dispatch(
                toastError("View name cannot be longer than 255 characters")
            );

            return;
        }

        setViewNewName(newValue);
        setViewNewNameError(false);
    };

    const onNameSave = () => {
        if (!viewNewNameTrimmed) {
            return;
        }

        const currentViews = columnSettings[tableSettingTab];

        for (const view of currentViews) {
            if (view.view_data.title.trim() === viewNewNameTrimmed) {
                setViewNewNameError(true);

                dispatch(toastError("View name already exists"));

                return;
            }
        }

        const { view_id, view_data } =
            columnSettings[tableSettingTab][selectedViewIndex[tableSettingTab]];

        if (
            saveMode === columnViewSaveModes.SAVE_DEFAULT_AS ||
            saveMode === columnViewSaveModes.SAVE_VIEW_AS
        ) {
            dispatch(
                addColumnSetting({
                    view_data: {
                        ...view_data,
                        widths: {
                            ...view_data.widths,
                            ...userResizedColumns[tableSettingTab],
                        },
                        title: viewNewNameTrimmed,
                        channel: selectedChannels[0].label,
                    },
                })
            ).then((newView) => {
                onApply(tableSettingTab, newView);
            });
        } else if (saveMode === columnViewSaveModes.RENAME) {
            dispatch(
                editColumnSetting({
                    view_id,
                    view_data: {
                        ...view_data,
                        title: viewNewNameTrimmed,
                        channel: selectedChannels[0].label,
                    },
                })
            ).then((newView) => {
                onApply(tableSettingTab, newView);
            });
        }

        setSaveMode(null);
    };

    const onNameSaveCancel = () => {
        setSaveMode(null);
    };

    const onSaveDefaultViewAsClick = () => {
        setSaveMode(columnViewSaveModes.SAVE_DEFAULT_AS);
    };

    const onSaveViewClick = () => {
        const { view_id, view_data } =
            columnSettings[tableSettingTab][selectedViewIndex[tableSettingTab]];

        dispatch(
            editColumnSetting({
                view_id,
                view_data: {
                    ...view_data,
                    widths: {
                        ...view_data.widths,
                        ...userResizedColumns[tableSettingTab],
                    },
                },
            })
        ).then((newView) => {
            onApply(tableSettingTab, newView);
        });
    };

    const onSaveViewAsClick = () => {
        setSaveMode(columnViewSaveModes.SAVE_VIEW_AS);
    };

    const onApplyClick = () => {
        onApply(
            tableSettingTab,
            columnSettings[tableSettingTab][selectedViewIndex[tableSettingTab]]
        );
    };

    const onCancelClick = () => {
        onClose();
    };

    return (
        <div className={`${tableStyles["container"]} flex-column`}>
            <div
                className={`${tableStyles["main-container"]} flex-column flex-1`}
            >
                <div
                    className={`${tableStyles["header"]} center-space-between`}
                >
                    <div className={`${tableStyles["title"]}`}>
                        Table settings
                    </div>
                    <button
                        className={`${tableStyles["close-btn"]} pointer`}
                        onClick={onCloseClick}
                    >
                        &times;
                    </button>
                </div>
                {tableSettingTab > -1 ? (
                    <TabContext value={tableSettingTab}>
                        <TabList
                            onChange={(_, newTab) => setTableSettingTab(newTab)}
                        >
                            {basePriceSettings ? (
                                <Tab
                                    label="Attributes Settings"
                                    value={
                                        tableSettingTabIds.BASE_ATTRIBUTE_SETTINGS
                                    }
                                />
                            ) : null}
                            {regionalPriceSettings ? (
                                <Tab
                                    label="Attributes Settings"
                                    value={
                                        tableSettingTabIds.REGIONAL_ATTRIBUTE_SETTINGS
                                    }
                                />
                            ) : null}
                            {priceListSettings ? (
                                <Tab
                                    label="Price List Settings"
                                    value={
                                        tableSettingTabIds.PRICE_LIST_SETTINGS
                                    }
                                />
                            ) : null}
                            {buyingRollUpsSettings ? (
                                <Tab
                                    label="Attributes Settings"
                                    value={tableSettingTabIds.BUYING_ROLL_UPS}
                                />
                            ) : null}
                            {planningRollUpSettings ? (
                                <Tab
                                    label="Attributes Settings"
                                    value={tableSettingTabIds.PLANNING_ROLL_UPS}
                                />
                            ) : null}
                        </TabList>
                        {basePriceSettings ? (
                            <TabPanel
                                value={
                                    tableSettingTabIds.BASE_ATTRIBUTE_SETTINGS
                                }
                                currentTab={tableSettingTab}
                                className="flex-1 min-height-0"
                            >
                                <ViewSettings
                                    views={
                                        columnSettings[
                                            tableSettingTabIds
                                                .BASE_ATTRIBUTE_SETTINGS
                                        ]
                                    }
                                    setViews={onViewChange}
                                    selectedViewIndex={
                                        selectedViewIndex[tableSettingTab]
                                    }
                                    setSelectedViewIndex={onSelectedViewChange}
                                    onEdit={onEdit}
                                    selectedChannels={selectedChannels}
                                />
                            </TabPanel>
                        ) : null}
                        {regionalPriceSettings ? (
                            <TabPanel
                                value={
                                    tableSettingTabIds.REGIONAL_ATTRIBUTE_SETTINGS
                                }
                                currentTab={tableSettingTab}
                                className="flex-1 min-height-0"
                            >
                                <ViewSettings
                                    views={
                                        columnSettings[
                                            tableSettingTabIds
                                                .REGIONAL_ATTRIBUTE_SETTINGS
                                        ]
                                    }
                                    setViews={onViewChange}
                                    selectedViewIndex={
                                        selectedViewIndex[tableSettingTab]
                                    }
                                    setSelectedViewIndex={onSelectedViewChange}
                                    onEdit={onEdit}
                                    selectedChannels={selectedChannels}
                                />
                            </TabPanel>
                        ) : null}
                        {priceListSettings ? (
                            <TabPanel
                                value={tableSettingTabIds.PRICE_LIST_SETTINGS}
                                currentTab={tableSettingTab}
                                className="flex-1 min-height-0"
                            >
                                <ViewSettings
                                    priceListSettings
                                    views={
                                        columnSettings[
                                            tableSettingTabIds
                                                .PRICE_LIST_SETTINGS
                                        ]
                                    }
                                    setViews={onViewChange}
                                    selectedViewIndex={
                                        selectedViewIndex[tableSettingTab]
                                    }
                                    setSelectedViewIndex={onSelectedViewChange}
                                    onEdit={onEdit}
                                    selectedChannels={selectedChannels}
                                />
                            </TabPanel>
                        ) : null}
                        {buyingRollUpsSettings ? (
                            <TabPanel
                                value={tableSettingTabIds.BUYING_ROLL_UPS}
                                currentTab={tableSettingTab}
                                className="flex-1 min-height-0"
                            >
                                <ViewSettings
                                    buyingRollUpsSettings
                                    views={
                                        columnSettings[
                                            tableSettingTabIds.BUYING_ROLL_UPS
                                        ]
                                    }
                                    setViews={onViewChange}
                                    selectedViewIndex={
                                        selectedViewIndex[tableSettingTab]
                                    }
                                    setSelectedViewIndex={onSelectedViewChange}
                                    onEdit={onEdit}
                                    onApplyClick={onApplyClick}
                                    selectedChannels={selectedChannels}
                                />
                            </TabPanel>
                        ) : null}
                        {planningRollUpSettings ? (
                            <TabPanel
                                value={tableSettingTabIds.PLANNING_ROLL_UPS}
                                currentTab={tableSettingTab}
                                className="flex-1 min-height-0"
                            >
                                <ViewSettings
                                    planningRollUpSettings
                                    views={
                                        columnSettings[
                                            tableSettingTabIds.PLANNING_ROLL_UPS
                                        ]
                                    }
                                    setViews={onViewChange}
                                    selectedViewIndex={
                                        selectedViewIndex[tableSettingTab]
                                    }
                                    setSelectedViewIndex={onSelectedViewChange}
                                    onEdit={onEdit}
                                    onApplyClick={onApplyClick}
                                    selectedChannels={selectedChannels}
                                />
                            </TabPanel>
                        ) : null}
                    </TabContext>
                ) : null}
            </div>
            <div className={`${tableStyles["divider"]}`}></div>
            <div
                className={`${tableStyles["actions-container"]} center-space-between`}
            >
                {saveMode ? (
                    <div className="align-center">
                        <OutlinedInput
                            id="outlined-adornment-amount"
                            placeholder="View name"
                            style={{ height: 36 }}
                            value={viewNewName}
                            error={viewNewNameError}
                            autoFocus
                            onChange={onViewNewNameChange}
                        />
                        <DoneIcon
                            className={`${
                                viewNewNameTrimmed ? "pointer" : "no-drop"
                            }`}
                            sx={{
                                marginLeft: "20px",
                                color: "#758498",
                                fontSize: 24,
                            }}
                            onClick={onNameSave}
                        />
                        <CloseIcon
                            className="pointer"
                            sx={{
                                marginLeft: "20px",
                                color: "#758498",
                                fontSize: 24,
                            }}
                            onClick={onNameSaveCancel}
                        />
                    </div>
                ) : tableSettingTab > -1 ? (
                    <>
                        <div className={`gap-12 align-center`}>
                            {columnSettings[tableSettingTab][
                                selectedViewIndex[tableSettingTab]
                            ]?.view_id === -1 ? (
                                <Button
                                    variant="outlined"
                                    onClick={onSaveDefaultViewAsClick}
                                >
                                    Save View
                                </Button>
                            ) : (
                                <>
                                    <Button
                                        variant="contained"
                                        disabled={
                                            isEqual(
                                                columnSettings[tableSettingTab][
                                                    selectedViewIndex[
                                                        tableSettingTab
                                                    ]
                                                ],
                                                columnSettingsOriginal[
                                                    tableSettingTab
                                                ][
                                                    selectedViewIndex[
                                                        tableSettingTab
                                                    ]
                                                ]
                                            ) &&
                                            Object.keys(
                                                userResizedColumns[
                                                    tableSettingTab
                                                ]
                                            ).length === 0
                                        }
                                        onClick={onSaveViewClick}
                                    >
                                        Update View
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        onClick={onSaveViewAsClick}
                                    >
                                        Save View As
                                    </Button>
                                </>
                            )}
                        </div>
                        <div className={`gap-12 align-center`}>
                            <Button variant="contained" onClick={onApplyClick}>
                                Apply
                            </Button>
                            <Button variant="outlined" onClick={onCancelClick}>
                                Cancel
                            </Button>
                        </div>
                    </>
                ) : null}
            </div>
        </div>
    );
}

TableSettings.propTypes = {
    planningRollUpSettings: PropTypes.bool,
    onApply: PropTypes.func,
    onClose: PropTypes.func,
    buyingRollUpsSettings: PropTypes.bool,
};

export default TableSettings;
